import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllowanceRoutingModule } from './allowance-routing.module';
import {SharedModule} from '../../../../shared/shared.module';
import { AllowanceComponent } from './view-allowance/allowance.component';
import { AddAllowanceComponent } from './add-allowance/add-allowance.component';
import { EditAllowanceComponent } from './edit-allowance/edit-allowance.component';
import { MemberComponent } from './member/member.component';


@NgModule({
  declarations: [
    AllowanceComponent,
    AddAllowanceComponent,
    EditAllowanceComponent,
    MemberComponent
  ],
  imports: [
    CommonModule,
    AllowanceRoutingModule,
    SharedModule
  ]
})
export class AllowanceModule { }
