<div class="row">
    <div class="col-lg-12">
        <div class="row">
         <div class="col-lg-6">
            <div class="card">
               
                <div class="card-body">
                    <h3 class="title">Bank Submission Forms</h3>
                    <table class="table table-bordered">
                        <thead class="table-head">
                            <tr>
                                <th>#</th>
                                <th>Summary </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                    <tr>
                                        <td>1</td>
                                        <td>All Staff & Deductions</td>
                                        <td> <a class="btn btn-secondary btn-sm text-white btn-custom"><i class="fa fa-eye me-2"></i>View</a></td>
                                    </tr>
                                
                        </tbody>
                       
                    </table>
                </div>
            </div>
         </div>
         <div class="col-lg-6">
            <div class="card">
               
                <div class="card-body bg-warning text-white opacity-2">
                    <h3 class="title">Bank Total Amount</h3>
                    <div class="tile_stats_count">
                        <span class="count_top"><i class="fa fa-money"></i> Amount to be Transacted From Bank Account</span>
                        <div class="mt-2" style="font-size:32px">Tsh 700,000,000/=</div>
                    </div>
                </div>
            </div>
         </div>
        </div>
    </div>
    <div class="col-lg-12 mt-4">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                   
                    <div class="card-body">
                        <h3 class="title">Tax Summary</h3>
                        <table class="table table-bordered">
                            <thead class="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Total Amount</th>
                                      
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Pay-E Summary</td>
                                    <td>5,000,000</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td><a  class="btn btn-secondary btn-sm text-white btn-custom"><i class="fa fa-file"></i> View Tax Summary</a></td>
                              </tr>
                                      
                                    
                            </tbody>
                           
                        </table>
                    </div>
                </div>
             </div>
             <div class="col-lg-6">
                <div class="card">
                   
                    <div class="card-body">
                        <h3 class="title">Pension Summary</h3>
                        <table class="table table-bordered">
                            <thead class="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Employer Contribution</th>
                                    <th>Employee Contribution</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>NSSF</td>
                                            <td>20000</td>
                                            <td>20000</td>
                                            <td> <a class="btn btn-secondary btn-sm text-white btn-custom"><i class="fa fa-file me-2"></i>Contribution Form</a></td>
                                        </tr>
                                    
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td scope="row">TOTAL</td>
                                    <td></td>
                                    <td>60000</td>
                                    <td>60000</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
             </div>
        </div>
    </div>
    <div class="col-lg-12 mt-4">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                   
                    <div class="card-body">
                        <h3 class="title">Deductions Status</h3>
                        <table class="table table-bordered">
                            <thead class="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Deduction Name</th>
                                    <th>Amount</th>
                                    <th>Action</th>   
                                </tr>
                            </thead>
                            <tbody>
                                
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>NHIF</td>
                                            <td>20000</td>
                                            <td> <a class="btn btn-secondary btn-sm text-white btn-custom"><i class="fa fa-file me-2"></i>View Report</a></td>
                                        </tr>
                                    
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td scope="row">TOTAL</td>
                                
                                    <td>60000</td>
                                    <td>Deduction Form</td>
                                   
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
             </div>
            <div class="col-lg-6">
                <div class="card">
                   
                    <div class="card-body">
                        <h3 class="title">Allowance Status</h3>
                        <table class="table table-bordered">
                            <thead class="table-head">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Transport Allowance</td>
                                            <td>60,000</td>
                                            
                                        </tr>
                                    
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td scope="row">TOTAL</td>
                                    <td></td>
                                    <td>60,000</td>
                                   
                                    
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
             </div>
        </div>
    </div>
</div>
