import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of,throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';


@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService) {
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authReq = req;
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      authReq = authReq.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
   
 
    // @ts-ignore
    return next.handle(authReq).pipe(
      // @ts-ignore
      
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        }
        else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          // setTimeout(()=>{this.logout()},250);
        }
       
        return throwError(errorMsg);
        
      })
    );
  }
logout(){
  localStorage.removeItem('core-user');
  localStorage.removeItem('access_token');
  this.router.navigateByUrl('/');
}
  // tslint:disable-next-line:typedef
  sessionExpNotification(msg: string) {
    Swal.fire({
      title: 'Session Expired',
      icon: 'info',
      text: msg,
      showCancelButton: false,
      confirmButtonText: `Sign-In`,
      denyButtonText: `Don't save`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('access_tokens');
        localStorage.removeItem('core-user');
        this.router.navigateByUrl('/');
      } else if (result.isDenied) {
      }
    });
  }
}

