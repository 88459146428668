import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreRoutingModule} from './core-routing.module';
import {MainlayoutComponent} from './components/mainlayout/mainlayout.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {BreadcrumbComponent} from './components/mainlayout/breadcrumb/breadcrumb.component';
import {ClockComponent} from './components/mainlayout/breadcrumb/clock/clock.component';
import {SharedModule} from '../shared/shared.module';
import {ChangePasswordComponent} from './components/password/change-password/change-password.component';
import {ProfileModule} from './components/profile/profile.module';
import {SettingsModule} from './components/settings/settings.module';
import {PayrollModule} from './components/payroll/payroll.module';
import {MainlayoutModule} from './components/mainlayout/mainlayout.module';



@NgModule({
  declarations: [
    MainlayoutComponent,
    DashboardComponent,
    BreadcrumbComponent,
    ClockComponent,
    ChangePasswordComponent,
  
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    ProfileModule,
    SettingsModule,
    PayrollModule,
    MainlayoutModule
  ],
    exports: [
        BreadcrumbComponent
    ],
  providers: [],
  bootstrap: [MainlayoutComponent],
})
export class CoreModule {
}
