import { Component, OnInit } from '@angular/core';
import {ExpandedLTR, ExpandedRTL, SlideInOut} from 'ng-material-multilevel-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    SlideInOut,
    ExpandedLTR,
    ExpandedRTL,
  ]
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  appitems = [
    {
      label: 'Dashboard',
      faIcon: 'fa-home 500px',
      link: '/core/',
    },
    {
      label: 'Employees',
      faIcon: 'fa-users 500px',
      link: '/core/payroll/employees',
    },
    {
      label: 'Payroll',
      faIcon: 'fa-solid fa-receipt 500px',
      items: [
       
        {
          label: 'Generate Payroll',
          link: '/core/payroll/generate-payroll',
        },
        {
          label: 'Payroll History',
          link: '/core/payroll/payroll-history',
        },
        {
          label: 'Allowances',
          link: '/core/settings/allowance'
        },
        {
          label: 'Pension Funds',
          link: '/core/settings/pension',
        },
        {
          label: 'Deductions',
          link: '/core/settings/deductions'
        },
        
        {
          label: 'Tax Status',
          link: '/core/settings/taxs'
        },
      ]
    },
    {
      label: 'Transactions',
      faIcon: 'fa-file 500px',
      link: '/core/settings/transactions',
    },
    {
      label: 'Settings',
      faIcon: 'fa-cog',
      items: [
        // {
        //   label: 'Mandatory Contributions',
        //   link: '/core/settings/mandatory-contributions',
        // },
       
       
        {
          label: 'Financial Year',
          link: '/core/settings/financial-year'
        },
        {
          label: 'Account Groups',
          link: '/core/settings/accountgroups',
        },
       
        {
          label: 'Chart Of Accounts',
          link: '/core/settings/chartofaccounts'
        },
        {
          label: 'Bank Account',
          link: '/core/settings/bankaccount',
        },
        {
          label: 'Users',
          link: '/core/settings/users'
        },
      ]
    },
    // {
    //   label: 'Reports',
    //   faIcon: 'fa-clipboard 500px',
    //   link: '/core/settings/reports',
    // },
  ];

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    listBackgroundColor: '#183765',
    fontColor: 'white',
    backgroundColor: '#183765',
    selectedListFontColor: '#ffc40d',
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: false,
    customTemplate: true,
  };

  // tslint:disable-next-line:typedef
  getClass(item: { faIcon: any; }) {
    return {
      [item.faIcon]: true
    };
  }

  // tslint:disable-next-line:typedef
  selectedItem($event: any) {
    console.log($event);
  }

}
