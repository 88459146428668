import { Component, OnInit ,ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Employees} from '../../../../models/employees';
import {EmployeesService} from '../../../../services/employees.service';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';
import { Deduction } from 'src/app/modules/core/models/deduction';
import { ActivatedRoute } from '@angular/router';
import { DeductionService } from 'src/app/modules/core/services/deduction.service';
import { BindingScope } from '@angular/compiler/src/render3/view/template';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  private unsubscribe = new Subject();
  isLoaded = false;
  Deduction:any;
  SubMembers:any;
  employees:any;
  deductionId:number;
  ded:any;
  emp:any

  emp_id:any= new Array();
  scope_emp:any= new Array();

 
  
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatTable) table: MatTable<Employees> | undefined;
  dataSource: MatTableDataSource<Employees> | undefined;

  displayedColumns = [
    'id',
    'full_name',
    'title',
    'phone_no',
    'email',
    'salary',
    'actions'
  ];
  constructor(public dialog: MatDialog, private employeesService: EmployeesService,
    private deductionService: DeductionService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchAllEmployees();
    this.fetchUserDeduction();
    this.fetchDeduction();
    this.fetchEmpDeduction();
   
  
  }
  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }


 

  fetchDeduction() {
    const id = this.route.snapshot.params['id']
    this.deductionId=id
    this.deductionService.getDeduction(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.ded = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
  fetchEmpDeduction() {
    const id = this.route.snapshot.params['id']
    this.deductionId=id
    
    this.deductionService.getEmpDeduction(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.employees = res.data;
     
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
  fetchUserDeduction() {
    const id = this.route.snapshot.params['id']
    this.deductionId=id
 
    this.deductionService.getUserDeduction(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.Deduction = res;
     
      for (let index = 0; index < this.Deduction.length; index++) {
       this.emp_id.push(this.Deduction[index].employee_id)
       
      }
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
  subscription() {
    const id = this.route.snapshot.params['id']
    this.deductionService.subscribedMembers(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.SubMembers = res;
      
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  
  userChecked(ded_id:number){
    let is_found= this.Deduction.find(each=>each.employee_id === ded_id);
     return is_found ? true : false
  }

  deleteMember(employee:any){
    let values ={}
    const id = employee.employee_id
    const deduction_id = employee.id

    values = {
      id: id,
      deduction_id:deduction_id,
    };

   
    const msg = 'You\'re about to delete this item';
    Swal.fire({
      text: 'You\'re about to delete this item',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.deductionService.unsubscribeMember(values).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllEmployees();
         
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }


 addMember(employee:any){

  let values = {};
  const id = employee.employee_id
  const itemData = this.ded;
  const deduction_id = employee.id;
  const amount = employee.amount;
  const percent = employee.percent;
  const category = itemData.status
  const employer_amount = itemData.employer_amount;
  const employer_percent = itemData.employer_percent;
  const type = itemData.category;
  const deadline= employee.deadline;
  
  
  values = {
    employee_id: id,
    deduction_id:deduction_id,
    amount:amount,
    percent:percent,
    employer_amount:employer_amount,
    employer_percent:employer_percent,
    type:type,
    category:category,
    deadline:deadline
  };
  const msg = 'You\'re about to delete this item';
  // this.alertConfirmation(msg, id);

  
    this.deductionService.subscribeMember(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
   
      },
      (error: HttpErrorResponse) => {
       
      }
    );
  

    
 }
  
  

  // tslint:disable-next-line:typedef
  fetchAllEmployees() {
    this.employeesService.getEmployees().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res
      this.emp= res
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string) {
    // @ts-ignore
    this.employees.filter = filterValue.trim().toLocaleLowerCase();
  }
}
