<div class="container-fluid">
  <div class="row my-5">
    <div class="col-md-3">
      <mat-card (click)="goEmPage()" class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">people</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Employees
          </mat-card-content>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3">
      <mat-card (click)="goGenPayrollPage()"
                class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">description</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Generate Payroll
          </mat-card-content>
        </div>
      </mat-card>
    </div>

  <div class="col-md-3">
    <mat-card (click)="goPayrollHisPage()"
              class="mat-elevation-z0 clickable mat-card-upper-bg">
      <div class="matcard">
        <mat-card-title class="text-center">
          <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">history</span>
        </mat-card-title>
        <mat-card-content class="card_content">
          Payroll History
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
</div>
