import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './settings.component';
import {SettingsGuard} from '../../../shared/guards/settings.guard';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
  },
  {
    path: 'mandatory-contributions',
    data: {
      title: 'Mandatory Contribution',
      breadcrumb: 'Mandatory Contribution',
    },
    loadChildren: () => import('./mandatory-contributions/mandatory-contributions.module').then(m => m.MandatoryContributionsModule),
  },
  {
    path: 'bands',
    data: {
      title: 'Bands',
      breadcrumb: 'Bands',
    },
    loadChildren: () => import('./bands/bands.module').then(m => m.BandsModule),
  },
  {
    path: 'allowance',
    data: {
      title: 'Allowances',
      breadcrumb: 'Allowances',
    },
    loadChildren: () => import('./allowance/allowance.module').then(m => m.AllowanceModule),
  },
  {
    path: 'companysettings',
    data: {
      title: 'Company Settings',
      breadcrumb: 'Company Settings',
    },
    loadChildren: () => import('./companysettings/companysettings.module').then(m => m.CompanysettingsModule),
  },
  {
    path: 'taxs',
    data: {
      title: 'Taxs',
      breadcrumb: 'Taxs',
    },
    loadChildren: () => import('./tax/tax.module').then(m => m.TaxModule),
  },
  {
    path: 'pension',
    data: {
      title: 'Pension',
      breadcrumb: 'Pension',
    },
    loadChildren: () => import('./pension/pension.module').then(m => m.PensionModule),
  },
  {
    path: 'residential-status',
    data: {
      title: 'Residential Status',
      breadcrumb: 'Residential Status',
    },
    loadChildren: () => import('./residential-status/residential-status.module').then(m => m.ResidentialStatusModule),
  },
  {
    path: 'financial-year',
    data: {
      title: 'Financial Year',
      breadcrumb: 'Financial Year',
    },
    loadChildren: () => import('./financial-year/financial-year.module').then(m => m.FinancialYearModule),
  },
  {
    path: 'deductions',
    data: {
      title: 'Deduction',
      breadcrumb: 'Deduction',
    },
    loadChildren: () => import('./deductions/deductions.module').then(m => m.DeductionsModule),
  },
  {
    path: 'transactions',
    data: {
      title: 'Transactions',
      breadcrumb: 'Transactions',
    },
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
  },
  {
    path: 'reports',
    data: {
      title: 'Reports',
      breadcrumb: 'Reports',
    },
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'chartofaccounts',
    data: {
      title: 'Chart Of Accounts',
      breadcrumb: 'Chart Of Accounts',
    },
    loadChildren: () => import('./chartofaccounts/chartofaccounts.module').then(m => m.ChartofaccountsModule),
  },
  {
    path: 'accountgroups',
    data: {
      title: 'Account Groups',
      breadcrumb: 'Account Groups',
    },
    loadChildren: () => import('./accountgroups/accountgroups.module').then(m => m.AccountgroupsModule),
  },
  {
    path: 'bankaccount',
    data: {
      title: 'Bank Account',
      breadcrumb: 'Bank Account',
    },
    loadChildren: () => import('./bankaccount/bankaccount.module').then(m => m.BankaccountModule),
  },
  {
    path: 'users',
    data: {
      title: 'Users',
      breadcrumb: 'Users',
    },
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {
}
