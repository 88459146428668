import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  submitForm: any;
  click = false;
  isLoggedIn = false;
  token = 2;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.SubmitData();
    if (localStorage.getItem('core-user')) {
      this.isLoggedIn = true;
    }
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  // tslint:disable-next-line:typedef
  get f() {
    return this.submitForm.controls;
  }

  onUserRecoverPwd() {
    this.click = true;
    const b = {email: this.f.email.value};
    this.authService.onPwdRecover(b.email)
      .subscribe((response: any) => {
          this.click = false;
          this.router.navigate(['/']);
        },
        (error: HttpErrorResponse) => {
          this.click = false;
        });
  }

}
