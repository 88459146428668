import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SettingsRoutingModule} from './settings-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {RolesPermissionsModule} from './roles_permissions/roles-permissions.module';
import {UsersModule} from './users/users.module';
import {MandatoryContributionsModule} from './mandatory-contributions/mandatory-contributions.module';
import {BandsModule} from './bands/bands.module';
import {TaxModule} from './tax/tax.module';
import { AllowanceModule } from './allowance/allowance.module';
import { PensionModule } from './pension/pension.module';
import {FinancialYearModule} from './financial-year/financial-year.module';
import {HolidayModule} from './holiday/holiday.module';
import {ResidentialStatusModule} from './residential-status/residential-status.module';
import {DeductionsModule} from './deductions/deductions.module';
import {SettingsComponent} from './settings.component';
import {CompanyModule} from './company/company.module';


@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    RolesPermissionsModule,
    UsersModule,
    SharedModule,
    MandatoryContributionsModule,
    BandsModule,
    TaxModule, 
    AllowanceModule,
    PensionModule,
    FinancialYearModule,
    HolidayModule,
    ResidentialStatusModule,
    DeductionsModule,
    CompanyModule
  ]
})
export class SettingsModule {
}
