import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BandsRoutingModule } from './bands-routing.module';
import { AddBandComponent } from './add-band/add-band.component';
import { EditBandComponent } from './edit-band/edit-band.component';
import { ViewBandsComponent } from './view-bands/view-bands.component';
import {SharedModule} from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    AddBandComponent,
    EditBandComponent,
    ViewBandsComponent
  ],
  imports: [
    CommonModule,
    BandsRoutingModule,
    SharedModule
  ]
})
export class BandsModule { }
