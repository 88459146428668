import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  submitForm: any;
  click = false;
  hide = true;
  isLoggedIn = false;
  phoneno: any;
  fname: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.SubmitData();
    if (localStorage.getItem('core-user')) {
      this.isLoggedIn = true;
    }
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  // tslint:disable-next-line:typedef
  get f() {
    return this.submitForm.controls;
  }

  onUserLogin() {
    this.click = true;
    const b = {email: this.f.email.value, password: this.f.password.value};
    this.authService.onSub(b.email, b.password)
      .subscribe((response: any) => {
          const userRole = response.role;
          const isVer = response.isVerified;
          const userDetails = JSON.stringify(response);
          // tslint:disable-next-line:variable-name

          const user_details = btoa(userDetails);
          const access_token = response.access_token;
          if (userRole === 'ADMINISTRATOR' || userRole === 'HUMAN_RESOURCE') {
            localStorage.setItem('core-user', user_details);
            localStorage.setItem('access_token', access_token);
            this.router.navigateByUrl('/core');
            this.isLoggedIn = true;
          } else {
            this.router.navigateByUrl('/');
          }
        },
        (error: HttpErrorResponse) => {
          this.click = false;
        });
  }

}
