import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Company} from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends DefaultDataService<Company> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Company', http, httpUrlGenerator);
  }

  getCompanies(): Observable<Company[]> {
    const url = `${(this.API_BASEURL)}/companies`;
    return this.http.get<Company[]>(url);
  }

  getCompanyDetails(): Observable<any> {
    const url = `${(this.API_BASEURL)}/company-detail`;
    return this.http.get<any>(url);
  }

  addCompany(body: any): Observable<Company> {
    const url = `${this.API_BASEURL}/add-company`;
    return this.http.post<Company>(url, body);
  }

  updateCompany(id:number,data:any): Observable<any> {
    const url = `${this.API_BASEURL}/update-company/${id}`;
    return this.http.put<any>(url, data);
  }

  deleteCompany(body: any) {
    const url = `${this.API_BASEURL}/delete-company/${body.id}`;
    return this.http.delete(url, body);
  }
}
