import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {User} from '../../auth/models/user';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends DefaultDataService<User> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('User', http, httpUrlGenerator);
  }

  getUserDetails(): Observable<User[]> {
    const url = `${this.API_BASEURL}/user_profile`;
    return this.http.get<User[]>(url);
  }
   getCompanyDetails():Observable<any>{
    const url = `${this.API_BASEURL}/company-details`;
    return this.http.get<any>(url);

   }
   saveCompany(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/save-company`;
    return this.http.post<any>(url, body);
  }
  updateCompany(id:number,body: any): Observable<User> {
    const url = `${this.API_BASEURL}/update_company/${id}`;
    return this.http.put<User>(url, body);
  }
  updateUserProfile(body: any): Observable<User> {
    const url = `${this.API_BASEURL}/update_user_profile/${body.id}`;
    return this.http.put<User>(url, body);
  }

  updateUserPwd(body: any): Observable<User> {
    const url = `${this.API_BASEURL}/update_user_pwd/${body.id}`;
    return this.http.put<User>(url, body);
  }
}
