<ng-container *ngIf="isLoaded">
  <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
    <div class="row">
      <div class="col-md-9">
        <button (click)="onAddNew()" class="custom-btn" color="primary" mat-raised-button>
          <mat-icon>add</mat-icon>
          ADD NEW USER
        </button>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search</mat-label>
          <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Search"/>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-elevation-z6 table-responsive" style="margin-top: 30px">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> #</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="#"> {{row.id || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="first_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> FullName</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="FirstName"> {{row.first_name || 'N/A'}} {{row.last_name || 'N/A'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Phone Number"> {{row.phone_no || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Email"> {{row.email || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Role</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Role"> {{row.role || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Registered</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Registered"> {{row.created_at | mask: "0000-d0-M0"}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
            <button [matMenuTriggerFor]="menu" aria-label="More Options" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="onEdit(row)" aria-label="edit icon" color="primary" mat-menu-item
                      title="Edit">
                <mat-icon style="color: gray">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button (click)="onDelete(row)" aria-label="delete icon" color="primary" mat-menu-item
                      title="Delete">
                <mat-icon style="color: red">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator #paginator
                     [pageIndex]="0"
                     [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                     [pageSize]="10"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>

  <ng-template #noData>
    <app-norecords></app-norecords>
    <br/>
    <div align="center">
      <button (click)="onAddNew()" class="" color="primary" mat-button style="font-size: 20px">
        ADD NEW USER
      </button>
    </div>
  </ng-template>
</ng-container>
