<app-custom-dialog>
  <div header>Update Band</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('name').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Maximum Amount</mat-label>
        <input formControlName="amount" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
        <mat-error *ngIf="submitForm.get('amount').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Rate</mat-label>
        <input formControlName="rate" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('rate').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Financial Year</mat-label>
        <mat-select formControlName="fy_id">
          <mat-option *ngFor="let fy of fys"
                      [value]="fy.id">{{ fy.year }}</mat-option>
        </mat-select>
        <mat-error *ngIf="submitForm.get('fy_id').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
