import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../services/dashboard.service';
import {Chart} from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  statsData: any | undefined;
  submitForm: any;
  chartData:any;
  cat:any=[];
  labeldata: any = [];
  realdata: any = [];
  netdata: any = [];
  grossdata: any = [];
  userdata: any = [];
  basic_pay:any;
  net_pay:any;
  public chart: any;
  private unsubscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchStaticstics();
    this.dashboardService.getChartData().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.chartData = res?.data.charts;
     
      if(this.chartData!=null){
       for (let i = 0; i< this.chartData.length; i++) {
        this.cat.push(this.chartData[i].charts)
       this.labeldata.push(this.chartData[i].category)
       this.realdata.push(this.chartData[i].basic_pay)
      
       this.netdata.push(this.chartData[i].net_pay)
       this.userdata.push(this.chartData[i].total_users)
        
       }
     
       this.RenderChart(this.labeldata,this.realdata,this.userdata,this.netdata);
      }
      
      });
 
  }
  RenderChart(labeldata:any,maindata:any,  userdata:any,netdata:any) {
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: labeldata, 
         datasets: [
          {
            label: "Basic Payment",
            data: maindata,
            backgroundColor: '#183765'
          },
          
          {
            type: 'line',
            label: 'Total Employees',
            data: userdata,
            fill: false,
            borderColor: 'rgb(54, 162, 235)'
        },
          {
            label: "Net Payment",
            data: netdata,
            backgroundColor: '#fec40e'
          }  
        ]
      },
      options: {
        aspectRatio:2.5
      }
      
    });
    
  }

  goEmployeePage() {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/payroll/employee']);
    });
  }



  SubmitData() {
    this.submitForm = new FormGroup({});
  }
 

  fetchStaticstics() {
    this.dashboardService.getStatics().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.statsData = res;
      
    }, (error) => {
    });
  }
 
  

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
