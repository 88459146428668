<mat-nav-list class="">
  <app-avatar></app-avatar>
  <mat-divider></mat-divider>
  <br/>
  <ng-material-multilevel-menu (selectedItem)="selectedItem($event)" [configuration]='config' [items]='appitems'>
    <ng-template #listTemplate let-configuration="configuration" let-item="item">
      <div [dir]="configuration.rtlLayout  ? 'rtl' : 'ltr'" class="my-cool-menu-item my-font">
        <div class="title-and-image">
          <div class="icon-container icons">
            <span [ngClass]="getClass(item)"></span>
          </div>
          <div class="label-container nav-text">
            <span>{{item.label}}</span>
         
          </div>
        </div>
        <div *ngIf='item.hasChilden' class="icon-arrow-container">
          <mat-icon *ngIf="!configuration.rtlLayout" [@ExpandedLTR]="item.expanded ? 'yes' : 'no'">
            keyboard_arrow_down
          </mat-icon>
          <mat-icon *ngIf="configuration.rtlLayout" [@ExpandedRTL]="item.expanded ? 'yes' : 'no'">
            keyboard_arrow_down
          </mat-icon>
        </div>
      </div>
    </ng-template>
  </ng-material-multilevel-menu>
</mat-nav-list>
