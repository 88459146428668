import {Component, OnInit} from '@angular/core';
import {User} from '../../../../auth/models/user';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {EditProfileComponent} from '../edit-profile/edit-profile.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ProfileService} from '../../../services/profile.service';
import {ChangePasswordComponent} from '../../password/change-password/change-password.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  userData: any | undefined;
  loading = false;
  private unsubscribe = new Subject();
  imgDirectory :any = 'https://bizzyn-pos.s3.us-east-1.amazonaws.com/users/'
  constructor(public dialog: MatDialog, private profileService: ProfileService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.grabUserData();
  }

  grabUserData() {
    this.profileService.getUserDetails().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.userData  = res[0];
    }, (error: HttpErrorResponse) => {
    });
  }

  onEdit(user: User): void {
    const config: MatDialogConfig = {
      data: {
        user
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '1000px'
    };
    // @ts-ignore
    const dialogRef = this.dialog.open(EditProfileComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.grabUserData();
      }
    });
  }

  onPasswordChange(user: User): void {
    const config: MatDialogConfig = {
      data: {
        user
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '500px'
    };
    // @ts-ignore
    const dialogRef = this.dialog.open(ChangePasswordComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.grabUserData();
        localStorage.removeItem('core-user');
        localStorage.removeItem('access_token');
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
