import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Role} from '../models/role';
import {environment} from '../../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends DefaultDataService<Role> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Role', http, httpUrlGenerator);
  }

  getRoles(): Observable<Role[]> {
    const url = `${(this.API_BASEURL)}/roles`;
    return this.http.get<Role[]>(url);
  }

  addRole(body: any): Observable<Role> {
    const url = `${this.API_BASEURL}/add-role`;
    return this.http.post<Role>(url, body);
  }

  updateRole(body: any): Observable<Role> {
    const url = `${this.API_BASEURL}/update-role/${body.id}`;
    return this.http.put<Role>(url, body);
  }

  deleteRole(body: any) {
    const url = `${this.API_BASEURL}/delete-role/${body.id}`;
    return this.http.delete(url, body);
  }
}
