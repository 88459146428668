import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {Error404Component} from './modules/shared/components/error404/error404.component';
import { CoreGuard } from './modules/shared/guards/core.guard';
const routes: Routes = [
  {
   
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'core',
    data: {
      title: 'Home',
      breadcrumb: 'Home',
    },
    loadChildren: () => import('./modules/core/core.module').then(m => m.CoreModule),
  },
  {
    path: '.+',
    component: Error404Component
  },
  { path: 'pension', loadChildren: () => import('./modules/core/components/settings/pension/pension.module').then(m => m.PensionModule) },
  { path: 'allowance', loadChildren: () => import('./modules/core/components/settings/allowance/allowance.module').then(m => m.AllowanceModule) },
  { path: 'companysettings', loadChildren: () => import('./modules/core/components/settings/companysettings/companysettings.module').then(m => m.CompanysettingsModule) },
  { path: 'chartofaccounts', loadChildren: () => import('./modules/core/components/settings/chartofaccounts/chartofaccounts.module').then(m => m.ChartofaccountsModule) },
  { path: 'accountgroups', loadChildren: () => import('./modules/core/components/settings/accountgroups/accountgroups.module').then(m => m.AccountgroupsModule) },
  { path: 'bankaccount', loadChildren: () => import('./modules/core/components/settings/bankaccount/bankaccount.module').then(m => m.BankaccountModule) },
  { path: 'transactions', loadChildren: () => import('./modules/core/components/settings/transactions/transactions.module').then(m => m.TransactionsModule) },
  { path: 'reports', loadChildren: () => import('./modules/core/components/settings/reports/reports.module').then(m => m.ReportsModule) },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['/']); // or redirect to default route
    };
  }
}
