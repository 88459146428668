import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Validation from '../../../shared/confirmed.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  submitForm: any;
  click = false;
  isLoggedIn = false;
  hide = true;
  hidec = true;
  token: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.SubmitData();
    if (localStorage.getItem('core-user')) {
      this.isLoggedIn = true;
    }
    this.token = this.route.snapshot.paramMap.get('token');
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    }, {
      validators: [Validation.match('password', 'confirm_password')]
    });
  }

  // tslint:disable-next-line:typedef
  get f() {
    return this.submitForm.controls;
  }

  onUserResetPwd() {
    this.click = true;
    const b = {email: this.f.email.value, password: this.f.password.value, token: this.token};
    this.authService.onPwdReset(b.email, b.password, b.token)
      .subscribe((response: any) => {
          this.click = false;
          this.router.navigate(['/']);
        },
        (error: HttpErrorResponse) => {
          this.click = false;
        });
  }

}
