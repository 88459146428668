import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Holiday} from '../models/holiday';

@Injectable({
  providedIn: 'root'
})
export class HolidayService extends DefaultDataService<Holiday> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Holiday', http, httpUrlGenerator);
  }

  getHolidays(): Observable<Holiday[]> {
    const url = `${(this.API_BASEURL)}/holiday`;
    return this.http.get<Holiday[]>(url);
  }

  addHoliday(body: any): Observable<Holiday> {
    const url = `${this.API_BASEURL}/add-holiday`;
    return this.http.post<Holiday>(url, body);
  }

  updateHoliday(body: any): Observable<Holiday> {
    const url = `${this.API_BASEURL}/update-holiday/${body.id}`;
    return this.http.put<Holiday>(url, body);
  }

  deleteHoliday(body: any) {
    const url = `${this.API_BASEURL}/delete-holiday/${body.id}`;
    return this.http.delete(url, body);
  }
}
