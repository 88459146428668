<div class="subtoolbar-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10">
        <ol class="breadcrumb">
          <li *ngFor="let breadcrumb of breadcrumbs">
            <span style="cursor: pointer;" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
              {{ breadcrumb.label }}
            </span>
          </li>
        </ol>
      </div>
      <div class="col-md-2">
<!--        <app-clock></app-clock>-->
      </div>
    </div>
  </div>
</div>
