<app-custom-dialog>
  <div header>Add Holiday</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('name').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Date</mat-label>
        <input [matDatepicker]="holiday_date" formControlName="date" matInput readonly>
        <mat-datepicker-toggle [for]="holiday_date" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #holiday_date></mat-datepicker>
        <mat-error *ngIf="submitForm.get('date').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
