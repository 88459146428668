import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ResidentialStatusRoutingModule} from './residential-status-routing.module';
import {AddResidentialStatusComponent} from './add-residential-status/add-residential-status.component';
import {EditResidentialStatusComponent} from './edit-residential-status/edit-residential-status.component';
import {ViewResidentialStatusComponent} from './view-residential-status/view-residential-status.component';
import {SharedModule} from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    AddResidentialStatusComponent,
    EditResidentialStatusComponent,
    ViewResidentialStatusComponent
  ],
  imports: [
    CommonModule,
    ResidentialStatusRoutingModule,
    SharedModule
  ]
})
export class ResidentialStatusModule {
}
