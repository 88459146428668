<ng-container *ngIf="isLoaded">
  <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
    <div class="row">
      <div class="col-md-4">
      </div>
      <div class="col-md-5"></div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search</mat-label>
          <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Search"/>
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive" style="margin-top: 30px; ">
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z3">
        <ng-container matColumnDef="employee_id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> #</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="#"> {{row.employee_id || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="full_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="FullName"> {{row.full_name || 'N/A'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="basic_salary">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Basic Pay</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Basic Pay"> {{row.basic_salary | mask: 'separator':','}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gross_taxable_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Gross Tax Pay</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Gross Tax Pay"> {{ row.gross_pay | mask: 'separator':','}}  </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pension">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Pension Funds</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Pension">{{row.total_pension | mask: 'separator':','}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="deduction">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Deductions</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Deduction"> {{(row.total_deduction | mask: 'separator':',') || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="allowance">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Allowance</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Allowance"> {{(row.total_allowance | mask: 'separator':',') || 'N/A' }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="taxable_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Taxable Amount</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Taxable Pay"> {{row.taxable_pay  | mask: 'separator':',' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="paye">
          <mat-header-cell *matHeaderCellDef mat-sort-header> PAYE</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="PAYE"> {{row.total_paye | mask: 'separator':',' }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="net_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Net Pay</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Net Pay"> {{row.total_net_pay | mask: 'separator':','}} </mat-cell>
        </ng-container>


                <ng-container matColumnDef="account_no">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="Account No"> {{row.account_no || 'N/A'}} </mat-cell>
                </ng-container>

<!--        <ng-container matColumnDef="account_no">-->
<!--          <th *matHeaderCellDef mat-sort-header></th>-->
<!--          <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">-->
<!--            <div class="example-element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">-->
<!--                Account: {{row.account_no || 'N/A'}}<br />-->
<!--                Account: {{row.account_no || 'N/A'}}<br />-->
<!--                Account: {{row.account_no || 'N/A'}}-->
<!--            </div>-->
<!--          </td>-->
<!--&lt;!&ndash;          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;          <mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" data-label="Account No">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="example-element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">&ndash;&gt;-->
<!--&lt;!&ndash;                   Account: {{row.account_no || 'N/A'}}<br />&ndash;&gt;-->
<!--&lt;!&ndash;              Account: {{row.account_no || 'N/A'}}<br />&ndash;&gt;-->
<!--&lt;!&ndash;              Account: {{row.account_no || 'N/A'}}<br />&ndash;&gt;-->
<!--&lt;!&ndash;              Account: {{row.account_no || 'N/A'}}<br />&ndash;&gt;-->
<!--&lt;!&ndash;              Account: {{row.account_no || 'N/A'}}<br />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-cell>&ndash;&gt;-->
<!--        </ng-container>-->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
                 [class.example-expanded-row]="row.isExpanded" (click)="row.isExpanded = !row.isExpanded">
        </mat-row>
      </mat-table>
      <mat-paginator #paginator
                     [pageIndex]="0"
                     [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                     [pageSize]="10"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    <br/><br/>
    <span class="title-2">PAYROLL DETAILS</span>
    <hr/>
    <form [formGroup]="submitForm" autocomplete="off" class="flex gap-4 justify-start" style="padding-bottom: 30px">
      <mat-form-field appearance="outline" class="w-25 inline">
        <mat-label>Month</mat-label>
        <input [matDatepicker]="date" formControlName="month_year" matInput readonly>
        <mat-datepicker-toggle [for]="date" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #date (monthSelected)="chosenMonthHandler($event, date)"
                        (yearSelected)="chosenYearHandler($event)"
                        startView="multi-year"
        ></mat-datepicker>
        <mat-error *ngIf="submitForm.get('month_year').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
      <button (click)="onSave()" [disabled]="submitForm.invalid || click" class="action-btn" color="primary"
              mat-raised-button>CREATE PAYROLL
      </button>
    </form>
  </div>

  <ng-template #noData>
    <app-norecords></app-norecords>
  </ng-template>
</ng-container>
