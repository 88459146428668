import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Employees} from '../models/employees';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends DefaultDataService<Employees> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Employees', http, httpUrlGenerator);
  }

  getEmployees(): Observable<Employees[]> {
    const url = `${(this.API_BASEURL)}/employees`;
    return this.http.get<Employees[]>(url);
  }

  getPayroll(): Observable<Employees[]> {
    const url = `${(this.API_BASEURL)}/get-current-payroll`;
    return this.http.get<Employees[]>(url);
  }

  addEmployee(body: any): Observable<Employees> {
    const url = `${this.API_BASEURL}/add-employee`;
    return this.http.post<Employees>(url, body);
  }

  updateEmployee(body: any): Observable<Employees> {
    const url = `${this.API_BASEURL}/update-employee/${body.id}`;
    return this.http.put<Employees>(url, body);
  }

  deleteEmployee(body: any) {
    const url = `${this.API_BASEURL}/delete-employee/${body.id}`;
    return this.http.delete(url, body);
  }
}
