import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ViewResidentialStatusComponent} from './view-residential-status/view-residential-status.component';

const routes: Routes = [
  {
    path: '',
    component: ViewResidentialStatusComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResidentialStatusRoutingModule {
}
