<app-custom-dialog>
  <div header>Add Residential Status</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <mat-select formControlName="name">
          <mat-option value="RESIDENTIAL">RESIDENTIAL</mat-option>
          <mat-option value="NON_RESIDENTIAL">NON_RESIDENTIAL</mat-option>
        </mat-select>
        <mat-error *ngIf="submitForm.get('name').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
