import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Payroll} from '../../../../models/payroll';
import {PayrollService} from '../../../../services/payroll.service';
import { ProfileService } from './../../../../services/profile.service';
import {HttpErrorResponse} from '@angular/common/http';
import { CompanyService } from 'src/app/modules/core/services/company.service';
@Component({
  selector: 'app-employee-payslip',
  templateUrl: './employee-payslip.component.html',
  styleUrls: ['./employee-payslip.component.css']
})
export class EmployeePayslipComponent implements OnInit {

  isLoaded = false;
  payrollData: any;
  companyData:any;
  imgDirectory :any = 'https://bizzyn-pos.s3.us-east-1.amazonaws.com/company/'
  private unsubscribe = new Subject();

  constructor(
    private payrollService: PayrollService,
    private companyService:CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeePayslipComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.grabCompanyData();
    
  }

  SubmitData() {
 
 this.payrollData = this.data


  }
  grabCompanyData() {
    this.companyService.getCompanyDetails().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.companyData  = res;
    }, (error: HttpErrorResponse) => {
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
 






}
