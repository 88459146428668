import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HolidayRoutingModule} from './holiday-routing.module';
import {AddHolidayComponent} from './add-holiday/add-holiday.component';
import {EditHolidayComponent} from './edit-holiday/edit-holiday.component';
import {ViewHolidaysComponent} from './view-holidays/view-holidays.component';
import {SharedModule} from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    AddHolidayComponent,
    EditHolidayComponent,
    ViewHolidaysComponent
  ],
  imports: [
    CommonModule,
    HolidayRoutingModule,
    SharedModule
  ]
})
export class HolidayModule {
}
