<div *ngIf="!isLoggedIn">
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
   
      <div class="col-lg-6 cont d-lg-flex d-none position-absolute end-0 justify-content-center flex-column">
        <div style="position:absolute;top:2% !important;">
          <img height="50%" src="https://bizzyn.com/wp-content/uploads/2022/07/Asset-3.png" width="30%"
          >
        </div>
        <img src="assets/imgs/icons/payroll.png" height="50%"  width="70%" alt="" style="overflow: hidden;margin-left: 11%;">
      </div>

      <div class="col-lg-6 cont2 d-flex flex-column mx-lg-0 mx-auto end-0 justify-content-center pe-0">
          <div body style="margin-top: 80px;">
            <span class="title-2">Welcome Back</span><br />
            <br />
            <p class="text-lead" style="margin-left: 11%;">Please enter your email and new password</p>
            <form [formGroup]="submitForm" autocomplete="off" style="margin-left: 11%;">
              <mat-form-field appearance="outline" [style.width.px]=327>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="email"/>
                <mat-error *ngIf="submitForm.get('email').hasError('required')">
                  Field is required
                </mat-error>
                <mat-error *ngIf="submitForm.get('email').hasError('email')">
                  Invailid email (eg. johndoe@example.com)
                </mat-error>
              </mat-form-field>
    
              <mat-form-field appearance="outline" [style.width.px]=327>
                <mat-label>New Password</mat-label>
                <input
                  matInput
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                  placeholder="Password"
                />
                <mat-icon matSuffix (click)="hide = !hide"
                >{{ hide ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-error *ngIf="submitForm.get('password').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
    
              <mat-form-field appearance="outline" [style.width.px]=327>
                <mat-label>Confirm Password</mat-label>
                <input
                  matInput
                  formControlName="confirm_password"
                  [type]="hidec ? 'password' : 'text'"
                  placeholder="Password"
                />
                <mat-icon matSuffix (click)="hidec = !hidec"
                >{{ hidec ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-error *ngIf="submitForm.get('confirm_password').hasError('required')">
                  Field is required
                </mat-error>
                <mat-error *ngIf="submitForm.get('confirm_password').hasError('matching')">
                  Password must match
                </mat-error>
              </mat-form-field>
    
            </form>
            <button mat-raised-button color="primary"
                    [style.width.px]=327
                    [disabled]="submitForm.invalid || click"
                    (click)="onUserResetPwd()" class="custom-btn btn-lg" style="width : 100%;">
              Reset
            </button>
            <div class="bottom">
                <p>Already have an Account? <a href="javascipt:" routerLink="/" style="color:#1a3765">Login</a></p>
            </div>
            &nbsp;&nbsp;
            
            <br /><br />
    
          </div>
     
      </div>
    </div>
  </div>
 
</div>
<app-error404 *ngIf="isLoggedIn"></app-error404>







