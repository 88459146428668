import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {EmployeesService} from '../../../../services/employees.service';
import {ResidentialStatusService} from '../../../../services/residential-status.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Employees} from '../../../../models/employees';
import {BandService} from '../../../../services/band.service';
import {DeductionService} from '../../../../services/deduction.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {

  submitForm: any;
  click = false;
  rs: any = [];
  bands: any = [];
  myNssf: any;
  myNhif: any;
  deductions: any;
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private employeesService: EmployeesService,
    private rsService: ResidentialStatusService,
    private bandService: BandService,
    private deductionService: DeductionService,
    @Inject(MAT_DIALOG_DATA) public data: { emp: Employees },
    public dialogRef: MatDialogRef<EditEmployeeComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchRS();
    this.fetchBands();
    this.fetchDeductions();
  }

  SubmitData() {
    const phoneNo = '0[0-9]{9}';
    const itemData = this.data.emp;
    const title = itemData.title;
    const full_name = itemData.full_name;
    const gender = itemData.gender;
    const phone_no = itemData.phone_no;
    const email = itemData.email;
    const address = itemData.address;
    const res_id = itemData.res_id;
    const basic_salary = itemData.basic_salary;
    const allowance = itemData.allowance;
    const bank_name = itemData.bank_name;
    const bank_ac_name = itemData.bank_ac_name;
    const bank_ac_no = itemData.bank_ac_no;
    const tin_no = itemData.tin_no;
    const ssn = itemData.ssn;
    const status = itemData.status;
   

    this.submitForm = new FormGroup({
      title: new FormControl(title, [Validators.required]),
      full_name: new FormControl(full_name, [Validators.required]),
      gender: new FormControl(gender, [Validators.required]),
      phone_no: new FormControl(phone_no, [ Validators.pattern(phoneNo)]),
      email: new FormControl(email),
      address: new FormControl(address),
      res_id: new FormControl(res_id, [Validators.required]),
      basic_salary: new FormControl(basic_salary, [Validators.required]),
      allowance: new FormControl(allowance),
      bank_name: new FormControl(bank_name),
      bank_ac_name: new FormControl(bank_ac_name),
      bank_ac_no: new FormControl(bank_ac_no),
      tin_no: new FormControl(tin_no),
      ssn: new FormControl(ssn),
      status: new FormControl(status, [Validators.required]),
    });
  }

  fetchRS() {
    this.rsService.getResidentialStatus().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.rs = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchBands() {
    this.bandService.getBands().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.bands = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchDeductions() {
    this.deductionService.getDeductions().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.deductions = res?.map((x) => x.name);
      }, (error: HttpErrorResponse) => {
      });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.emp;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.employeesService.updateEmployee(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
