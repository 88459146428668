<app-custom-dialog>
  <div header>Add Deduction</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input formControlName="name"  matInput placeholder=""/>
       
        <mat-error *ngIf="submitForm.get('name').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Category</mat-label>
        
        <mat-select formControlName="category">
          <mat-option value="1">Fixed Deduction</mat-option>
          <mat-option value="2">Monthly Deduction</mat-option>
        </mat-select>
       
      </mat-form-field>
     
      <mat-radio-group aria-label="Select an option" formControlName="is_percentage" style="margin-top: 20px;">
        <mat-label style="margin-right: 10px;">Type:</mat-label>
        <mat-radio-button [value]="true">Percentage</mat-radio-button>
        <mat-radio-button [value]="false">Amount</mat-radio-button>
        <mat-error *ngIf="submitForm.get('is_percentage').hasError('required')">
          Field is required
        </mat-error>
      </mat-radio-group>
       <div *ngIf="submitForm.value.is_percentage === true">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Employee Percent</mat-label>
          <input formControlName="percent" mask="separator.0" matInput placeholder=""/>
         
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Employer Percent</mat-label>
          <input formControlName="employer_percent"  mask="separator.0" matInput placeholder=""/>
         
        </mat-form-field>
       </div>
     <div *ngIf="submitForm.value.is_percentage === false">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Employee Amount</mat-label>
        <input formControlName="amount" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
        
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Employer Amount</mat-label>
        <input formControlName="employer_amount" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
       
      </mat-form-field>
     </div>
      
     <mat-form-field appearance="outline" class="w-100">
      <mat-label>Select Status</mat-label>
      
      <mat-select formControlName="status">
        <mat-option value="0">Based on Gross Salary</mat-option>
        <mat-option value="1">Based on Basic Salary</mat-option>
      </mat-select>
      <mat-error *ngIf="submitForm.get('status').hasError('required')">
          Field is required
        </mat-error>
    </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Description</mat-label>
        <textarea formControlName="description" matInput placeholder="Ex. Deduction details..."></textarea>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
