import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DeductionService} from '../../../../services/deduction.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Deduction} from '../../../../models/deduction';

@Component({
  selector: 'app-edit-deduction',
  templateUrl: './edit-deduction.component.html',
  styleUrls: ['./edit-deduction.component.css']
})
export class EditDeductionComponent implements OnInit {

  submitForm: any;
  click = false;
  private unsubscribe = new Subject();

  constructor(
    private deductionService: DeductionService,
    @Inject(MAT_DIALOG_DATA) public data: { deduction: Deduction },
    public dialogRef: MatDialogRef<EditDeductionComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    const itemData = this.data.deduction;
    const name = itemData.name;
    const maxAmount = itemData.amount;
    const percent = itemData.percent;
    const category = itemData.category;
    const employer_amount = itemData.employer_amount;
    const employer_percent = itemData.employer_percent;
    const description = itemData.description;
    const is_percentage = itemData.is_percentage;
    this.submitForm = new FormGroup({
    
      amount: new FormControl(maxAmount, [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      category: new FormControl(category,[Validators.required]),
      percent: new FormControl(percent),
      employer_amount: new FormControl(employer_amount),
      employer_percent: new FormControl(employer_percent),
      description: new FormControl(description),
      is_percentage :new FormControl(is_percentage,[Validators.required]),
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.deduction;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.deductionService.updateDeduction(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
