import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {EmployeesService} from '../../../../services/employees.service';
import {ResidentialStatusService} from '../../../../services/residential-status.service';
import {BandService} from '../../../../services/band.service';
import {DeductionService} from "../../../../services/deduction.service";

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {

  submitForm: any;
  click = false;
  rs: any = [];
  bands: any = [];
  deductions: any;
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private employeesService: EmployeesService,
    private rsService: ResidentialStatusService,
    private bandService: BandService,
    private deductionService: DeductionService,
    public dialogRef: MatDialogRef<AddEmployeeComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchRS();
    this.fetchBands();
    this.fetchDeductions();
  }

  SubmitData() {
    const phoneNo = '0[0-9]{9}';
    this.submitForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      full_name: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      phone_no: new FormControl('', [ Validators.pattern(phoneNo)]),
      email: new FormControl('', ),
      address: new FormControl('', ),
      res_id: new FormControl('', [Validators.required]),
      basic_salary: new FormControl('', [Validators.required]),
      allowance: new FormControl(''),
      bank_name: new FormControl(''),
      bank_ac_name: new FormControl(''),
      bank_ac_no: new FormControl(''),
      tin_no: new FormControl(),
      ssn: new FormControl(),
    
    });
  }

  fetchRS() {
    this.rsService.getResidentialStatus().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.rs = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchBands() {
    this.bandService.getBands().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.bands = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchDeductions() {
    this.deductionService.getDeductions().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.deductions = res?.map((x) => x.name);
      }, (error: HttpErrorResponse) => {
      });
  }

  onSave() {
    this.click = true;
    const values = this.submitForm.value;
    this.employeesService.addEmployee(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


}
