import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EmployeesService} from '../../../../services/employees.service';
import {takeUntil} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Payroll} from '../../../../models/payroll';
import {EmployeePayslipComponent} from '../employee-payslip/employee-payslip.component';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {PayrollService} from '../../../../services/payroll.service';
import {DatePipe, formatNumber} from '@angular/common';
import * as FileSaver from 'file-saver';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import { DeductionService } from 'src/app/modules/core/services/deduction.service';
import { CompanyService } from 'src/app/modules/core/services/company.service';

const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-view-payroll',
  templateUrl: './view-payroll.component.html',
  styleUrls: ['./view-payroll.component.css']
})
export class ViewPayrollComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  private unsubscribe = new Subject();
  isLoaded = false;
  submitForm: any;
  empData:any;
  testData:any=[];
  payslip:any[]=[];
  nh: any = [];
  fys: any = [];
  bPay: any;
  pfunds: any;
  eDed: any;
  tallw: any;
  gtp: any;
  taxPay: any;
  wcf: any;
  paye: any;
  sdl: any;
  loan: any;
  employerNhif: any;
  netPay: any;
  employerNssf: any;
  nssfTotal: any;
  payrollMonth: any;
  exportList: any[] = [];
  exportFilteredList: any[] = [];
  payrollDetail: any;
  dedDetail:any;
  payDed:any;
  Deduction:any;
  companyData:any;
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatTable) table: MatTable<Payroll> | undefined;
  dataSource: MatTableDataSource<Payroll> | undefined;

  displayedColumns = [
    'full_name',
    'basic_salary',
    'gross_taxable_pay',
    'deductions',
    'pension',
    'allowance',
    'taxable_pay',
    'paye',
    'net_pay',
    'account_no',
    'actions'
  ];

  constructor(
    public dialog: MatDialog,
    private payrollService: PayrollService,
    private employeesService: EmployeesService,
    private companyService:CompanyService,
    private router: Router,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.SubmitData();
    this.fetchPayroll();
    this.grabCompanyData();
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      month_year: new FormControl(moment(), [Validators.required])
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.submitForm.controls.month_year.value;
    ctrlValue.year(normalizedYear.year());
    this.submitForm.controls.month_year.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.submitForm.controls.month_year.value;
    ctrlValue.month(normalizedMonth.month());
    this.submitForm.controls.month_year.setValue(ctrlValue);
    datepicker.close();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  fetchPayroll() {
    this.payrollService.getPayrollHistory(this.payrollMonth).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res?.data.payroll;
      this.exportList = res?.data.payroll;
      this.payrollDetail = res?.data;
      this.payDed = res?.data.payroll;
      this.dedDetail=res?.data.deductions;
     
      this.bPay = res?.data.payroll.map((x) => parseInt(x.basic_salary)).reduce((acc, curr) => acc + curr, 0);
      this.pfunds = res?.data.payroll.map((x) => parseInt(x.total_pension)).reduce((acc, curr) => acc + curr, 0);
      this.eDed = res?.data.payroll.map((x) => parseInt(x.total_deduction)).reduce((acc, curr) => acc + curr, 0);
      this.tallw = res?.data.payroll.map((x) => parseInt(x.total_allowance)).reduce((acc, curr) => acc + curr, 0);
      this.gtp = res?.data.payroll.map((x) => parseInt(x.gross_pay)).reduce((acc, curr) => acc + curr, 0);
      this.taxPay = res?.data.payroll.map((x) => parseInt(x.taxable_pay)).reduce((acc, curr) => acc + curr, 0);
      this.wcf = res?.data.payroll.map((x) => parseInt(x.wcf)).reduce((acc, curr) => acc + curr, 0);
      this.paye = res?.data.payroll.map((x) => parseInt(x.total_paye)).reduce((acc, curr) => acc + curr, 0);
      this.sdl = res?.data.payroll.map((x) => parseInt(x.sdl)).reduce((acc, curr) => acc + curr, 0);
      this.loan = res?.data.payroll.map((x) => parseInt(x.loan_repayment)).reduce((acc, curr) => acc + curr, 0);
      this.employerNhif = res?.data.payroll.map((x) => parseInt(x.employer_nhif)).reduce((acc, curr) => acc + curr, 0);
      this.netPay = res?.data.payroll.map((x) => parseInt(x.total_net_pay)).reduce((acc, curr) => acc + curr, 0);
      this.employerNssf = res?.data.payroll.map((x) => parseInt(x.employee_nssf)).reduce((acc, curr) => acc + curr, 0);
      this.nssfTotal = res?.data.payroll.map((x) => parseInt(x.nssf_total)).reduce((acc, curr) => acc + curr, 0);
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

 

  onView(empId:number): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '900px'
    };
    const id = empId;
    const payrollID = this.payrollDetail.payroll_details.id
  
    this.payrollService.getPayslip(id,payrollID)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.payslip = res?.data;
      const dialogRef = this.dialog.open(EmployeePayslipComponent, {disableClose: true,
        panelClass: 'dialog-responsive',
        width: '900px',data:this.payslip});
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        // @ts-ignore
        if (result === 'updated') {
          //
        }
      });
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
   
  
   
    
  }

  onDelete(payrollDetail) {
    const id = payrollDetail;
    const msg = 'You\'re about to delete this payroll';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.payrollService.deletePayroll({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.goPayrollManagementPage();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  grabCompanyData() {
    this.companyService.getCompanyDetails().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.companyData  = res;
    }, (error: HttpErrorResponse) => {
    });
  }
  goPayrollManagementPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/payroll']);
    });
  }
  
  goToSummary() {
    let payrollMonth=''
    const payrollID = this.payrollDetail.payroll_details.id
    const payrollMyr =this.payrollDetail.payroll_details.month_year

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      
      this.router.navigate(['core/payroll/payroll-summary',{payrollMonth:payrollMyr}]);
      
    });
  }

  get f() {
    return this.submitForm.controls;
  }


  onSearch() {
    const my = this.datePipe.transform(this.f.month_year.value, 'MM/YYYY');
    this.payrollMonth = my;
    this.fetchPayroll();
  }

  exportExcel() {
    if (this.exportList.length > 0) {
      import('xlsx').then(xlsx => {
        const heading = [[
          'Name',
          'Basic Salary',
          'Gross Taxable Pay',
          'Pension Funds',
          'Deductions',
          'Allowance',
          'Taxable Pay',
          'PAYE',
          'Net Pay',
          'Account No.'
        ]];

        const footer = [[
          'Name',
          'Basic Salary',
          'Gross Pay',
          'Pension Funds'
        ]];

        const myData = this.exportList.map(d => ({
          full_name: d.full_name,
          basic_salary: formatNumber(d.basic_salary, 'en-US', ''),
          gross_pay: formatNumber(d.gross_pay, 'en-US', ''),
          total_pension: formatNumber(d.total_pension, 'en-US', ''),
          total_deduction: formatNumber(d.total_deduction, 'en-US', ''),
          total_allowance: formatNumber(d.total_allowance, 'en-US', ''),
          taxable_pay: formatNumber(d.taxable_pay, 'en-US', ''),
          paye: formatNumber(d.total_paye, 'en-US', ''),
          net_pay: formatNumber(d.total_net_pay, 'en-US', ''),
          account_no: d.account_no
        }));

        // @ts-ignore
        const worksheet = xlsx.utils.json_to_sheet(myData, {skipHeader: true, origin: 'A2'});
        const workbook = {Sheets: {data: worksheet}, SheetNames: []};

        xlsx.utils.sheet_add_aoa(worksheet, heading);
        xlsx.utils.sheet_add_aoa(worksheet, footer);
        xlsx.utils.book_append_sheet(workbook, worksheet);
        const wscols = [
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
          {wch: 15},
        ];
        worksheet['!cols'] = wscols;
        const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, 'payroll');
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const monthYear = this.datePipe.transform(this.f.month_year.value, 'YYYY-MM');
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_for_' + monthYear + EXCEL_EXTENSION);
  }

  generatePdf() {
    const monthYear = this.datePipe.transform(this.f.month_year.value, 'YYYY-MM');
    const companyName = this.companyData.name
    const pdf = new jsPDF('landscape');
    const prepare: any[][] = [];
    this.exportList.forEach(e => {
      const tempObj = [];
      tempObj.push(e.full_name);
      tempObj.push(formatNumber(e.basic_salary, 'en-US', ''));
      tempObj.push(formatNumber(e.gross_pay, 'en-US', ''));
      tempObj.push(formatNumber(e.total_pension, 'en-US', ''));
      tempObj.push(formatNumber(e.total_deduction, 'en-US', ''));
      tempObj.push(formatNumber(e.total_allowance, 'en-US', ''));
      tempObj.push(formatNumber(e.taxable_pay, 'en-US', ''));
      tempObj.push(formatNumber(e.total_paye, 'en-US', ''));
      tempObj.push(formatNumber(e.total_net_pay, 'en-US', ''));
      tempObj.push(e.account_no);
      prepare.push(tempObj);
    });
    pdf.setFontSize(16);
  
    pdf.text('Payroll for ' + this.datePipe.transform(monthYear, 'MMMM, y'), 14, 10);
   
    pdf.setFont('Trebuchet MS');
    pdf.setFontSize(10);
    pdf.setTextColor(99);
    (pdf as any).autoTable({
      styles: {overflow: 'linebreak', cellWidth: 'wrap', cellPadding: 1, fontSize: 7},
      columnStyles: {text: {cellWidth: 'auto'}},
      columns: [
        {header: 'Name', dataKey: 'full_name'},
        {header: 'Basic Salary', dataKey: 'basic_salary'},
        {header: 'Gross Taxable Pay', dataKey: 'gross_pay'},
        {header: 'Pension Funds', dataKey: 'total_pension'},
        {header: 'Deductions', dataKey: 'total_deduction'},
        {header: 'Allowance', dataKey: 'total_allowance'},
        {header: 'Taxable Pay', dataKey: 'taxable_pay'},
        {header: 'PAYE', dataKey: 'total_paye'},
        {header: 'Net Pay', dataKey: 'total_net_pay'},
        {header: 'Account No.', dataKey: 'account_no'}
      ],
      body: prepare,
      theme: 'striped',
      didDrawCell: (data: { column: { index: any; }; }) => {
      }
    });
    (pdf as any).autoTable({
      styles: {overflow: 'linebreak', cellWidth: 'wrap', cellPadding: 1, fontSize: 7, fontFamily: 'courier'},
      columnStyles: {text: {cellWidth: 'auto'}},
      columns: [
        {header: 'Basic Pay'},
        {header: 'Gross Taxable Pay'},
        {header: 'Pension Funds'},
        {header: 'Deductions'},
        {header: 'Allowance'},
        {header: 'Taxable Pay'},
        {header: 'Net Pay'},
      ],
      body: [[
        formatNumber(this.bPay, 'en-US', ''),
        formatNumber(this.gtp, 'en-US', ''),
        formatNumber(this.pfunds, 'en-US', ''),
        formatNumber(this.eDed, 'en-US', ''),
        formatNumber(this.tallw, 'en-US', ''),
        formatNumber(this.taxPay, 'en-US', ''),
        formatNumber(this.netPay, 'en-US', ''),
        formatNumber(this.wcf, 'en-US', ''),
        formatNumber(this.paye, 'en-US', ''),
        formatNumber(this.sdl, 'en-US', ''),
        formatNumber(this.loan, 'en-US', ''),
        formatNumber(this.employerNhif, 'en-US', ''),
        
        formatNumber(this.employerNssf, 'en-US', ''),
        formatNumber(this.nssfTotal, 'en-US', '')
      ]],
      theme: 'plain',
      didDrawCell: (data: { column: { index: any; }; }) => {
      }
    });
    // Download PDF doc
    pdf.save('payroll_for_' + monthYear + '.pdf');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
