import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProfileService} from '../../../services/profile.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../../auth/models/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Validation from '../../../../shared/confirmed.validator';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  submitForm: any;
  click = false;
  hide = true;
  hidec = true;
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public dialogRef: MatDialogRef<ChangePasswordComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    }, {
      validators: [Validation.match('password', 'confirm_password')]
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const userData = this.data.user;
    const userId = userData.id;

    values = {
      ...values,
      id: userId
    };
    this.profileService.updateUserPwd(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

}
