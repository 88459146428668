import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PayrollComponent} from './payroll.component';

const routes: Routes = [
  {
    path: '',
    component: PayrollComponent,
  },
  {
    path: 'employees',
    data: {
      title: 'Employees',
      breadcrumb: 'Employees',
    },
    loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule),
  },
  {
    path: 'generate-payroll',
    data: {
      title: 'Generate Payroll',
      breadcrumb: 'Generate Payroll',
    },
    loadChildren: () => import('./generate-payroll/generate-payroll.module').then(m => m.GeneratePayrollModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollRoutingModule {
}
