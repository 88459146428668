import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mainlayout',
  templateUrl: './mainlayout.component.html',
  styleUrls: ['./mainlayout.component.css']
})
export class MainlayoutComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public logged_user: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:variable-name
    this.grabUserData();
  }

  // tslint:disable-next-line:typedef
  grabUserData() {
    // tslint:disable-next-line:variable-name
    const user_data = localStorage.getItem('core-user');
    if (typeof user_data === 'string') {
      const userData = atob(user_data);
      const userDetails = JSON.parse(userData);
      this.logged_user = userDetails;
    }
  }

  goProfilePage() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['core/profile']);
    });
  }

  // tslint:disable-next-line:typedef
  onLogout() {
    localStorage.removeItem('core-user');
    localStorage.removeItem('access_token');
    this.router.navigateByUrl('/');
  }

}
