import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {User} from '../models/user';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {environment} from '../../../../environments/environment.dev';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends DefaultDataService<User> {

  public isLoggedIn$: BehaviorSubject<boolean>;
  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, 
    private router:Router,private httpBackend: HttpBackend) {
    super('User', http, httpUrlGenerator);
    const isLoggedIn = localStorage.getItem('core-user') === 'true';
    this.isLoggedIn$ = new BehaviorSubject(isLoggedIn);
    if(this.isLoggedIn){
      this.router.navigate(['core']);
    }
  }
  ipRecognition():Observable<any>{
    return this.http.get('https://ipapi.co/json/',{});    
  }
  
  getToken() {
    return localStorage.getItem('access_token');
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }

  onSub(email: string, password: string): Observable<any> {
    // tslint:disable-next-line:new-parens
    const form = new FormData;
    form.append('email', email);
    form.append('password', password);
    return this.http.post(`${this.API_BASEURL}/sign-me-in`, form);
  }

  onPwdRecover(email: string): Observable<any> {
    // tslint:disable-next-line:new-parens
    const form = new FormData;
    form.append('email', email);
    return this.http.post(`${this.API_BASEURL}/recover-password`, form);
  }

  onRegister(data:any){
    return this.http.post(`${this.API_BASEURL}/sign-up`, data);
  }

  onPwdReset(email: string, password: string, token: string): Observable<any> {
  
    const form = new FormData;
    form.append('email', email);
    form.append('password', password);
    form.append('token', token);
    return this.http.post(`${this.API_BASEURL}/reset-password`, form);
  }

}
