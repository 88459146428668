import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Holiday} from '../../../../models/holiday';
import {HolidayService} from '../../../../services/holiday.service';
import {AddHolidayComponent} from '../add-holiday/add-holiday.component';
import {EditHolidayComponent} from '../edit-holiday/edit-holiday.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-holidays',
  templateUrl: './view-holidays.component.html',
  styleUrls: ['./view-holidays.component.css']
})
export class ViewHolidaysComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<Holiday> | undefined;
  dataSource: MatTableDataSource<Holiday> | undefined;

  displayedColumns = ['name', 'date', 'actions'];

  constructor(
    public dialog: MatDialog,
    private holidayService: HolidayService
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchHolidays();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(AddHolidayComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchHolidays();
      }
    });
  }

  onEdit(holiday: Holiday): void {
    const config: MatDialogConfig = {
      data: {
        holiday
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(EditHolidayComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchHolidays();
      }
    });
  }

  onDelete(holiday: Holiday) {
    const id = holiday.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.holidayService.deleteHoliday({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchHolidays();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  fetchHolidays() {
    this.holidayService.getHolidays().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res;
        this.isLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
