<div *ngIf="!isLoggedIn">
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <div class="col-lg-6 cont d-lg-flex d-none position-absolute end-0 justify-content-center flex-column">
        <div style="position:absolute;top:2% !important;">
          <img height="50%" src="https://bizzyn.com/wp-content/uploads/2022/07/Asset-3.png" width="30%"
          >
        </div>
        <img src="assets/imgs/icons/payroll.png" height="50%"  width="70%" alt="" style="overflow: hidden;margin-left: 11%;">
      </div>

      <div class="col-lg-6 cont2 d-flex flex-column mx-lg-0 mx-auto end-0 justify-content-center pe-0">
          <div body style="margin-top: 80px;">
            <span class="title-2">Welcome Back</span><br />
            <br />
            <p class="text-lead" style="margin-left: 11%;">Please enter your details</p>
            <form [formGroup]="submitForm" autocomplete="off" style="margin-left: 11%;">
              <mat-form-field appearance="outline"  [style.width.px]=327 style="margin-top: 20px;">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput placeholder="email"/>
                <mat-error *ngIf="submitForm.get('email').hasError('required')">
                  Field is required
                </mat-error>
                <mat-error *ngIf="submitForm.get('email').hasError('email')">
                  Invailid email (eg. johndoe@example.com)
                </mat-error>
              </mat-form-field><br>
    
              <mat-form-field appearance="outline"  [style.width.px]=327>
                <mat-label>Password</mat-label>
                <input
                  [type]="hide ? 'password' : 'text'"
                  formControlName="password"
                  matInput
                  placeholder="Password"
                />
                <mat-icon (click)="hide = !hide" matSuffix
                >{{ hide ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-error *ngIf="submitForm.get('password').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field><br>
             <div class="d-flex">
              <div style="margin-right:70px">
                <mat-checkbox class="example-margin" color="primary">Remember me!</mat-checkbox>
              </div>
             
               <div>
                 <a href="javascipt:" routerLink="/recover-password" style="color:#1a3765">Forgot Password </a>
               </div> 
             </div>

            </form>
            <button [disabled]="submitForm.invalid || click"
            [style.width.px]=327
             class="custom-btn" mat-raised-button (click)="onUserLogin()">
              Login
            </button>
            <div class="bottom">
                <p>Don't have an Account? <a href="javascipt:" routerLink="/register" style="color:#1a3765">Create Account</a></p>
            </div>
            &nbsp;&nbsp;
            
            <br /><br />
    
          </div>
     
      </div>
    </div>
  </div>
 
</div>
<app-error404 *ngIf="isLoggedIn"></app-error404>
