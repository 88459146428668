<div class="card-group">
  <!-- <a routerLink="/core/payroll/employees"> -->
  <div class="card card-body zoom" style="cursor: pointer;">
    <div class="row">
      <div class="col-lg-10 float-left">
        <div class="dashboard-card-content_1">EMPLOYEES</div>
      </div>
      <div class="col-lg-2">
        <div class="float-right"><span class="material-icons" style="color: white;">badge</span></div>
      </div>
    </div>
    <hr/>
    <div class="dashboard-card-content_2">{{statsData?.data.employees | mask: 'separator':','}}</div>
  </div>
<!-- </a> -->
  <div class="card card-body zoom">
    <div class="row">
      <div class="col-lg-10 float-left">
        <div class="dashboard-card-content_1">Basic Salary</div>
      </div>
      <div class="col-lg-2">
        <div class="float-right"><span class="material-icons" style="color: white;">receipt</span></div>
      </div>
    </div>
    <hr/>
    <div class="dashboard-card-content_2">{{statsData?.data.basic_salary | mask: 'separator':','}}</div>
  </div>

  <div class="card card-body zoom">
    <div class="row">
      <div class="col-lg-10 float-left">
        <div class="dashboard-card-content_1">Gross Pay</div>
      </div>
      <div class="col-lg-2">
        <div class="float-right"><span class="material-icons" style="color: white;">people</span></div>
      </div>
    </div>
    <hr/>
    <div class="dashboard-card-content_2">{{statsData?.data.gross_pay | mask: 'separator':','}}</div>
  </div>

  <div class="card card-body zoom">
    <div class="row">
      <div class="col-lg-10 float-left">
        <div class="dashboard-card-content_1">NET PAY</div>
      </div>
      <div class="col-lg-2">
        <div class="float-right"><span class="material-icons" style="color: white;">article</span></div>
      </div>
    </div>
    <hr/>
    <div class="dashboard-card-content_2">{{statsData?.data.total_net_pay | mask: 'separator':','}}</div>
  </div>
</div>
<div class="chart-container" style="margin-top: 30px;">
  <h3>Payroll Summary</h3>
  <div class="card">
    
    <canvas  id="MyChart" >{{ chart }}</canvas>
  </div>

</div>