import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Payroll} from '../models/payroll';

@Injectable({
  providedIn: 'root'
})
export class PayrollService extends DefaultDataService<Payroll> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Payroll', http, httpUrlGenerator);
  }

  getPayroll(): Observable<Payroll[]> {
    const url = `${(this.API_BASEURL)}/payroll`;
    return this.http.get<Payroll[]>(url);
  }

  getPayrollHistory(myr: any): Observable<any> {
    const form = new FormData;
    form.append('myr', myr);
    return this.http.post(`${this.API_BASEURL}/payroll-history`, form);
  }

  getPayslip(id: number,payrollID:any): Observable<any> {
    const url = `${(this.API_BASEURL)}/payslip/${payrollID}/${id}`;
    return this.http.get<any>(url);
  }
  getPayrollSummary(payrollID:any): Observable<any> {
    const url = `${(this.API_BASEURL)}/payroll-summary/${payrollID}`;
    return this.http.get<any>(url);
  }


  addPayroll(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-payroll`;
    return this.http.post<Payroll>(url, body);
  }

  deletePayroll(body: any) {
    const url = `${this.API_BASEURL}/delete-payroll/${body.id}`;
    return this.http.delete(url, body);
  }


  getChartOfAccount(): Observable<any> {
    const url = `${(this.API_BASEURL)}/chart-of-accounts`;
    return this.http.get<any>(url);
  }
  getAccountType(): Observable<any> {
    const url = `${(this.API_BASEURL)}/account-type`;
    return this.http.get<any>(url);
  }
  addChartOfAccount(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-chart-of-account`;
    return this.http.post<Payroll>(url, body);
  }
  updateChartOfAccount(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/update-chart-account/${body.id}`;
    return this.http.put<any>(url, body);
  }
  deleteChartOfAccount(body: any) {
    const url = `${this.API_BASEURL}/delete-chart-account/${body.id}`;
    return this.http.delete(url, body);
  }
  getBankAccounts(): Observable<any> {
    const url = `${(this.API_BASEURL)}/bank-accounts`;
    return this.http.get<any>(url);
  }
  getBanks(): Observable<any> {
    const url = `${(this.API_BASEURL)}/banks`;
    return this.http.get<any>(url);
  }
  getCurrencies(): Observable<any> {
    const url = `${(this.API_BASEURL)}/currencies`;
    return this.http.get<any>(url);
  }
  getAccountGroups(): Observable<any> {
    const url = `${(this.API_BASEURL)}/account-groups`;
    return this.http.get<any>(url);
  }
  addAccountGroup(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-account-group`;
    return this.http.post<Payroll>(url, body);
  }
  updateAccountGroup(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/update-account-group/${body.id}`;
    return this.http.put<any>(url, body);
  }
  deleteAccountGroup(body: any) {
    const url = `${this.API_BASEURL}/delete-account-group/${body.id}`;
    return this.http.delete(url, body);
  }
  addBankAccount(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-bank-account`;
    return this.http.post<Payroll>(url, body);
  }
  updateBankAccount(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/update-bank-account/${body.id}`;
    return this.http.put<any>(url, body);
  }
  deleteBankAccount(body: any) {
    const url = `${this.API_BASEURL}/delete-bank-account/${body.id}`;
    return this.http.delete(url, body);
  }
  getTransactions(): Observable<any> {
    const url = `${(this.API_BASEURL)}/transactions`;
    return this.http.get<any>(url);
  }
  getBalanceSheet(date:any): Observable<any> {
    const url = `${(this.API_BASEURL)}/report`;
    return this.http.post<any>(url,date);
  }

   addTransaction(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-transaction`;
    return this.http.post<any>(url, body);
  }
}
