import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {FinancialYear} from '../../../../models/financial-year';
import {AddFinancialYearComponent} from '../add-financial-year/add-financial-year.component';
import {EditFinancialYearComponent} from '../edit-financial-year/edit-financial-year.component';
import {FinancialYearService} from '../../../../services/financial-year.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-financial-year',
  templateUrl: './view-financial-year.component.html',
  styleUrls: ['./view-financial-year.component.css']
})
export class ViewFinancialYearComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<FinancialYear> | undefined;
  dataSource: MatTableDataSource<FinancialYear> | undefined;

  displayedColumns = ['year', 'status', 'actions'];

  constructor(
    public dialog: MatDialog,
    private financialYearService: FinancialYearService
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchFinancialYears();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(AddFinancialYearComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchFinancialYears();
      }
    });
  }

  onEdit(fy: FinancialYear): void {
    const config: MatDialogConfig = {
      data: {
        fy
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(EditFinancialYearComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchFinancialYears();
      }
    });
  }

  onDelete(fy: FinancialYear) {
    const id = fy.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.financialYearService.deleteFinancialYear({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchFinancialYears();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  onChange(fy: FinancialYear) {
    const id = fy.id;
    const msg = 'You\'re about to activate this year';
    this.onChangealertConfirmation(msg, id);
  }

  onChangealertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.financialYearService.changeFinancialYear({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchFinancialYears();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  fetchFinancialYears() {
    this.financialYearService.getFinancialYears().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res;
        this.isLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
