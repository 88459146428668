<app-custom-dialog>
  <div header>Add Financial Year</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Year</mat-label>
        <input formControlName="year" matInput placeholder="" mask="0000"/>
        <mat-error *ngIf="submitForm.get('year').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
