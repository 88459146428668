<ng-container *ngIf="isLoaded">
  <div *ngIf="employees.length > 0; else noData">
    <div class="row">
     
      <div class="col-md-6 col-sm-12 col-xs-12 offset-sm-3 list-group">
        <div class="list-group-item border-0">
            <table class="table">
                <thead>
                    <tr>
                        
                        <th>Name</th>
                        <th *ngIf="ded.is_percentage === 0">
                          Amount
                         </th>
                         <th *ngIf="ded.is_percentage === 1">
                          Percent
                          </th>
                      

                    </tr>
                </thead>
                <tbody>
                    <tr>

                        
                        <td>{{ded.name}}</td>
                        <td *ngIf="ded.is_percentage === 0">
                          {{ded.amount}}
                         </td>
                         <td *ngIf="ded.is_percentage === 1">
                          {{ded.percent}}
                          </td>
                       
                      
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
      <div class="col-md-9">
      
          
       
      </div>
      <div class="col-md-3 float-lg-end" style="float: right !important;">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search</mat-label>
          <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Search"/>
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-sortable">
        <thead class="table-head py-3">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Title</th>
            <th>Phone Number</th>
            <th>Basic Salary</th>
           <th *ngIf="ded.is_percentage === 0">
            Amount
           </th>
           <th *ngIf="ded.is_percentage === 1">
            Percent
            </th>
            <th *ngIf="ded.category === 2">
             Date
            </th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employees">
            <td>{{employee.employee_id}}</td>
            <td>{{employee.full_name}}</td>
            <td>{{employee.title}}</td>
            <td>{{employee.phone_no}}</td>
            <td>{{employee.basic_salary}}</td>
           
            <td *ngIf="ded.is_percentage === 0">
              <input class="form-control" placeholder="amount" value="{{employee.amount}}" 
              [(ngModel)]="employee.amount"  >
             
             </td>
             <td *ngIf="ded.is_percentage === 1">
              <input class="form-control" placeholder="percent"
              [(ngModel)]="employee.percent" value="{{employee.percent}}" 
              >
             </td>
  
            
             <td *ngIf="ded.category === 2">
            
              <input type="date" class="form-control" [(ngModel)]="employee.deadline"  value="{{Deduction.deadline}}">
              
             </td>
            
            <td>
             
              <input type="checkbox" value="{{employee.employee_id}}"  class="subscribe" 
              (change)="deleteMember(employee)" 
              checked="true" *ngIf="emp_id.includes(employee.employee_id)"
             >
             <input type="checkbox" value="{{employee.employee_id}}"  class="subscribe" 
              (change)="addMember(employee)" 
              *ngIf="!emp_id.includes(employee.employee_id)"
             >
            </td>
          </tr>
       
        </tbody>
      </table>
      <mat-paginator #paginator
                     [pageIndex]="0"
                     [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                     [pageSize]="10"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>

  </div>
  
  <!-- <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
    
    <div class="mat-elevation-z6 table-responsive" style="margin-top: 30px">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> #</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="#"> {{row.id || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="full_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> FullName</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="FullName"> {{row.full_name || 'N/A'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Title</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Title"> {{row.title || 'N/A'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Phone Number"> {{row.phone_no || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Email"> {{row.email || 'N/A'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="salary">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Basic Salary</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Salary"> {{row.basic_salary | mask: 'separator':',' || 'N/A'}} </mat-cell>
        </ng-container>
        

    
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
           
              <mat-checkbox class="example-margin"
              color="primary"
              [checked]="userChecked(row.id)"
              (change)="addMember(row)" 
               ></mat-checkbox>
        
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator #paginator
                     [pageIndex]="0"
                     [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                     [pageSize]="10"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div> -->

  <ng-template #noData>
    <app-norecords></app-norecords>
    <br/>
   
  </ng-template>
</ng-container>
