<app-custom-dialog>
  <div header>Add Company</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>
        <input formControlName="name" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('name').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Reference</mat-label>
        <input formControlName="ref" matInput placeholder="" mask="SSS"/>
        <mat-error *ngIf="submitForm.get('ref').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Address</mat-label>
        <input formControlName="address" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('address').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Phone Number</mat-label>
        <input formControlName="phone_number" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('phone_number').hasError('required')">
          Field is required (eg. 255789000001)
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder=""/>
        <mat-error *ngIf="submitForm.get('email').hasError('required')">
          Field is required (eg. peterjohn@example.com)
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Number of Employees</mat-label>
        <input formControlName="employees" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
        <mat-error *ngIf="submitForm.get('employees').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
