import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {RoleService} from '../../../../services/role.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Role} from '../../../../models/role';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {

  submitForm: any;
  click = false;
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private roleService: RoleService,
    @Inject(MAT_DIALOG_DATA) public data: { role: Role },
    public dialogRef: MatDialogRef<EditRoleComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    const roleData = this.data.role;
    const roleName = roleData.name;

    this.submitForm = new FormGroup({
      name: new FormControl(roleName, [Validators.required])
    });
  }


  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const roleData = this.data.role;
    const Id = roleData.id;

    values = {
      ...values,
      id: Id
    };
    this.roleService.updateRole(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
