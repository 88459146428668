import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Payroll} from '../../../../models/payroll';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EmployeesService} from '../../../../services/employees.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Employees} from '../../../../models/employees';
import {EmployeePayslipComponent} from '../employee-payslip/employee-payslip.component';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {PayrollService} from '../../../../services/payroll.service';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-generate-payroll',
  templateUrl: './generate-payroll.component.html',
  styleUrls: ['./generate-payroll.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class GeneratePayrollComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;
  submitForm: any;
  nh: any = [];
  fys: any = [];
  bPay: any;
  nHIF: any;
  empNSSF: any;
  allowance: any;
  gtp: any;
  taxPay: any;
  wcf: any;
  paye: any;
  sdl: any;
  loan: any;
  employerNhif: any;
  netPay: any;
  employerNssf: any;
  nssfTotal: any;
  click = false;
  payrollDetails: any = [];
  isExpanded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatTable) table: MatTable<Payroll> | undefined;
  dataSource: MatTableDataSource<Payroll> | undefined;

  displayedColumns = [
    'employee_id',
    'full_name',
    'basic_salary',
    'gross_taxable_pay',
    'deduction',
    'pension',
    'allowance',
    'taxable_pay',
    'paye',
    'net_pay',
    'account_no'
  ];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private employeesService: EmployeesService,
    private payrollService: PayrollService
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.SubmitData();
    this.fetchPayroll();
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      month_year: new FormControl(moment(), [Validators.required])
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.submitForm.controls['month_year'].value;
    ctrlValue.year(normalizedYear.year());
    this.submitForm.controls['month_year'].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.submitForm.controls['month_year'].value;
    ctrlValue.month(normalizedMonth.month());
    this.submitForm.controls['month_year'].setValue(ctrlValue);
    datepicker.close();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  fetchPayroll() {
    this.employeesService.getPayroll().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res.payroll_data;
      this.payrollDetails = res;
      this.isLoaded = true;
      this.isExpanded = false;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  onView(employees: Employees): void {
    const config: MatDialogConfig = {
      data: {
        employees
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '900px'
    };
    const dialogRef = this.dialog.open(EmployeePayslipComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        //
      }
    });
  }

  onSave() {
    this.click = true;
    const valueA = this.submitForm.value;
    const valuesB = this.payrollDetails;

    const Objs: any = {
      ...valueA,
      ...valuesB
    };
    this.payrollService.addPayroll(Objs).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.goPayrollManagementPage();
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  goPayrollManagementPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/payroll']);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
