import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Band} from '../../../../models/band';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';
import {AddCompanyComponent} from '../add-company/add-company.component';
import {EditCompanyComponent} from '../edit-company/edit-company.component';
import {Company} from '../../../../models/company';
import {CompanyService} from '../../../../services/company.service';

@Component({
  selector: 'app-view-companies',
  templateUrl: './view-companies.component.html',
  styleUrls: ['./view-companies.component.css']
})
export class ViewCompaniesComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<Band> | undefined;
  dataSource: MatTableDataSource<Band> | undefined;

  displayedColumns = ['id', 'name', 'reference', 'address', 'phone_number', 'email', 'employees', 'actions'];

  constructor(
    public dialog: MatDialog,
    private companyService: CompanyService
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchCompanies();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(AddCompanyComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchCompanies();
      }
    });
  }

  onEdit(company: Company): void {
    const config: MatDialogConfig = {
      data: {
        company
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(EditCompanyComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchCompanies();
      }
    });
  }

  onDelete(company: Company) {
    const id = company.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.companyService.deleteCompany({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchCompanies();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  fetchCompanies() {
    this.companyService.getCompanies().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res;
        this.isLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
