import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../../auth/models/user';
import {takeUntil} from 'rxjs/operators';
import {ProfileService} from '../../../services/profile.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  submitForm: any;
  click = false;
  hide = true;
  imageSrc: any = '';
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public dialogRef: MatDialogRef<EditProfileComponent>
  ) { }

  ngOnInit(): void {
    this.SubmitData();
  }
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    this.submitForm.get('photo').setValue(this.imageSrc); 
    
  }
  SubmitData() {
    const userData = this.data.user;
    const first_name = userData.first_name;
    const last_name = userData.last_name;
    const gender = userData.gender;
    const phoneNumber = userData.phone_no;
    const email = userData.email;
    const photo = userData.photo;

    this.submitForm = new FormGroup({
      first_name: new FormControl(first_name, [Validators.required]),
      last_name: new FormControl(last_name, [Validators.required]),
      gender: new FormControl(gender, [Validators.required]),
      phone_no: new FormControl(phoneNumber, [Validators.required]),
      email: new FormControl(email, [Validators.required]),
      photo:new FormControl(photo)
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const userData = this.data.user;
    const Id = userData.id;

    values = {
      ...values,
      id: Id
    };
    this.profileService.updateUserProfile(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
