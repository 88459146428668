import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-norecords',
  templateUrl: './norecords.component.html',
  styleUrls: ['./norecords.component.css']
})
export class NorecordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
