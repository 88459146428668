import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {AuthService} from '../auth/services/auth.service';
import {Error404Component} from './components/error404/error404.component';
import {SharedRoutingModule} from './shared-routing.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ToastrModule} from 'ngx-toastr';
import {MatMenuModule} from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatChipsModule} from '@angular/material/chips';
import {NgxMaskModule} from 'ngx-mask';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ChartsModule} from 'ng2-charts';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderComponent} from './components/loader/loader.component';
import {NorecordsComponent} from './components/norecords/norecords.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatStepperModule} from '@angular/material/stepper';
import {CustomCardComponent} from './components/custom-card/custom-card.component';
import {CustomDialogComponent} from './components/custom-dialog/custom-dialog.component';
import {NgxPrintModule} from 'ngx-print';
import {NgMaterialMultilevelMenuModule, MultilevelMenuService} from 'ng-material-multilevel-menu';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    date: 'shortDate',
    dateInput: 'YYYY-MM-DD',
    dateOutput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },

};

@NgModule({
  declarations: [Error404Component, LoaderComponent, NorecordsComponent, CustomCardComponent, CustomDialogComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    HttpClientModule,
    MatProgressBarModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatChipsModule,
    NgxMaskModule.forRoot(),
    MatTabsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ChartsModule,
    NgxSpinnerModule,
    TranslateModule,
    MatStepperModule,
    NgxPrintModule,
    NgMaterialMultilevelMenuModule
  ],
  exports: [
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    HttpClientModule,
    Error404Component,
    MatProgressBarModule,
    SweetAlert2Module,
    MatMenuModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatChipsModule,
    NgxMaskModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ChartsModule,
    NgxSpinnerModule,
    LoaderComponent,
    NorecordsComponent,
    TranslateModule,
    MatStepperModule,
    CustomDialogComponent,
    NgxPrintModule,
    CustomCardComponent,
    NgMaterialMultilevelMenuModule
  ],
  providers: [
    AuthService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MultilevelMenuService},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    DatePipe
  ],
})
export class SharedModule {
}
