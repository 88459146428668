<div class="row">
  <div class="col-md-8">
    <form [formGroup]="submitForm" autocomplete="off" class="flex gap-4 justify-start" style="padding-bottom: 30px">
      <mat-form-field appearance="outline" class="w-25 inline">
        <mat-label>Month</mat-label>
        <input [matDatepicker]="date" formControlName="month_year" matInput readonly>
        <mat-datepicker-toggle [for]="date" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #date (monthSelected)="chosenMonthHandler($event, date)"
                        (yearSelected)="chosenYearHandler($event)"
                        startView="multi-year"
        ></mat-datepicker>
        <mat-error *ngIf="submitForm.get('month_year').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>
      <button (click)="onSearch()" [disabled]="submitForm.invalid" class="action-btn" color="primary" mat-raised-button>
        SEARCH
      </button>
    </form>
  </div>
</div>

<ng-container *ngIf="isLoaded">
  <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
    <div class="row">
      <div class="col-md-4">
        <button [matMenuTriggerFor]="menu" class="custom-btn" color="primary"
                mat-raised-button>Export Results
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="exportExcel()">Excel</button>
          <button (click)="generatePdf()" mat-menu-item>PDF</button>
        </mat-menu>
      </div>
      <div class="col-md-5"></div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search</mat-label>
          <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Search"/>
        </mat-form-field>
      </div>
    </div>
    <br/><br/>
    <div class="mat-elevation-z0 table-responsive" style="margin-top: 30px">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="full_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="FullName"> {{row.full_name || 'N/A'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="basic_salary">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Basic Pay</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Basic Pay"> {{row.basic_salary | mask: 'separator':','}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gross_taxable_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Gross  Pay</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Gross Tax Pay"> {{row.gross_pay | mask: 'separator':','}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pension">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Pension Funds</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Pension Funds">{{row.total_pension | mask: 'separator':','}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="deductions">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Deductions</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Deductions"> {{row.total_deduction | mask: 'separator':','}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="allowance">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Allowance</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Allowance"> {{row.total_allowance | mask: 'separator':','}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="taxable_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Taxable Pay</mat-header-cell>
          <mat-cell *matCellDef="let row"
                    data-label="Taxable Pay"> {{row.taxable_pay | mask: 'separator':','}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="paye">
          <mat-header-cell *matHeaderCellDef mat-sort-header> PAYE</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="PAYE"> {{row.total_paye | mask: 'separator':','}} </mat-cell>
        </ng-container>

       


        <ng-container matColumnDef="net_pay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Net Pay</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Net Pay"> {{row.total_net_pay | mask: 'separator':','}} </mat-cell>
        </ng-container>

       

        <ng-container matColumnDef="account_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Account No</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Account No"> {{row.account_no || 'N/A'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
            <button [matMenuTriggerFor]="menu" aria-label="More Options" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <a [routerLink]="['/core/payroll/generate-payroll/employee-payslip', row.id]">
                <button  aria-label="edit icon" color="primary"
                mat-menu-item title="View">
               <mat-icon style="color: gray">description</mat-icon>
              <span>View Payslip</span>
             </button>
              </a> -->
              <button (click)="onView(row.employee_id)" aria-label="" color="primary" mat-menu-item
                      title="View">
                <mat-icon>description</mat-icon>
                <span>View Payslip</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
       
      </mat-table>
     
      
      <mat-paginator #paginator
                     [pageIndex]="0"
                     [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                     [pageSize]="10"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    
    <br/><br/><br/><br/>
    <table class="table table-bordered title-1">
      <thead>
      <tr>
        <th colspan="14" class="title-3">PAYROLL DETAILS <span class="float-right" style="cursor: pointer"
                                                               (click)="onDelete(payrollDetail?.payroll_details.id)"><mat-icon
          color="warn">delete</mat-icon></span>
        </th>
      </tr>
      <tr class="title-1">
        <th colspan="7">Payroll Reference No.</th>
        <td colspan="7">{{payrollDetail?.payroll_details.reference || 'N/A'}}</td>
      </tr>
      <tr class="title-1">
        <th colspan="7">MONTH</th>
        <td colspan="7">{{payrollDetail?.payroll_details.month_year || 'N/A'}}</td>
      </tr>
      <tr>
        <th colspan="14" class="title-3">SUMMARY IN TOTAL</th>
      </tr>
      <tr>
        <th>BASIC PAY</th>
        <th>GROSS TAXABLE PAY</th>
        <th>Pension Funds</th>
        <th>Deductions</th>
        <th>ALLOWANCE</th>
        <th>TAXABLE PAY</th>
        <th>PAYE</th>
        <th>NET PAY</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{bPay | mask: 'separator':','}}</td>
        <td>{{gtp | mask: 'separator':','}}</td>
        <td>{{pfunds | mask: 'separator':','}}</td>
        <td>{{eDed | mask: 'separator':','}}</td>
        <td>{{tallw | mask: 'separator':','}}</td>
        <td>{{taxPay | mask: 'separator':','}}</td>
        <td>{{paye | mask: 'separator':','}}</td>
        <td>{{netPay | mask: 'separator':','}}</td>
      
      </tr>
      </tbody>
    </table>
    <br/>
    <!-- <div class="row">
      <div class="float-lg-end">
        <button class="custom-btn" color="primary" (click)="goToSummary()"
        mat-raised-button>View Summary
       </button>
      </div>
    </div> -->
  </div>

  <ng-template #noData>
    <app-norecords></app-norecords>
  </ng-template>
</ng-container>
