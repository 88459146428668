import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {MandatorycontributionService} from '../../../../services/mandatorycontribution.service';
import {Mandatorycontribution} from '../../../../models/mandatorycontribution';

@Component({
  selector: 'app-edit-mandatory-contribution',
  templateUrl: './edit-mandatory-contribution.component.html',
  styleUrls: ['./edit-mandatory-contribution.component.css']
})
export class EditMandatoryContributionComponent implements OnInit {

  submitForm: any;
  click = false;
  private unsubscribe = new Subject();

  constructor(
    private mcService: MandatorycontributionService,
    @Inject(MAT_DIALOG_DATA) public data: { mc: Mandatorycontribution },
    public dialogRef: MatDialogRef<EditMandatoryContributionComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    const itemData = this.data.mc;
    const name = itemData.name;
    const rate = itemData.rate;

    this.submitForm = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      rate: new FormControl(rate, [Validators.required])
    });
  }


  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.mc;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.mcService.updateContribution(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
