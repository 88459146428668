import { Component, OnInit, Inject, Output } from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { PayrollService } from 'src/app/modules/core/services/payroll.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payroll-summary',
  templateUrl: './payroll-summary.component.html',
  styleUrls: ['./payroll-summary.component.css']
})
export class PayrollSummaryComponent implements OnInit {
  private unsubscribe = new Subject();
  payrollId:any;
  isLoaded = false;
  payrollDetail: any;
  payrollMonth: any;
  payrollSummary:any;
  constructor(private payrollService: PayrollService, private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.fetchPayroll();
    this.getPayrollSummary();
    // console.log(payrollId)
  }
  
  fetchPayroll() {
    const payrollID = this.route.snapshot.params['payrollMonth']
    this.payrollService.getPayrollHistory(payrollID).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
   
      this.payrollDetail = res?.data;
    
   
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
  getPayrollSummary(){
    const payrollID = this.route.snapshot.params['payrollMonth']

    this.payrollService.getPayrollSummary(payrollID).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
   
      this.payrollSummary = res?.data;
    

      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
}
