import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GeneratePayrollComponent} from './generate-payroll/generate-payroll.component';
import {ViewPayrollComponent} from './view-payroll/view-payroll.component';
import { EmployeePayslipComponent } from './employee-payslip/employee-payslip.component';
import { PayrollSummaryComponent } from './payroll-summary/payroll-summary.component';

const routes: Routes = [
  {
    path: '',
    component: GeneratePayrollComponent
  },
  {
    path: 'payroll-history',
    data: {
      title: 'Payroll-History',
      breadcrumb: 'Payroll-History',
    },
    component: ViewPayrollComponent
  },
  {
    path: 'payroll-summary',
    data: {
      title: 'Payroll-Summary',
      breadcrumb: 'Payroll-Summary',
    },
    component: PayrollSummaryComponent
  },
  {
    path: 'core/payroll/generate-payroll/employee-payslip/:id',
    component: EmployeePayslipComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeneratePayrollRoutingModule {
}
