import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {tap, catchError} from 'rxjs/operators';

@Injectable()
export class NotificationsInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // @ts-ignore
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.body.code === 200) {
            const successMsg = event.body.message;
            this.successNotification(successMsg);
          }
        }
      }),
      // @ts-ignore
      catchError((error) => {
        if (error.error.code === 422) {
          const errorMsg = error.error.message;
          this.errorNotification(errorMsg);
        } else if (error.status === 403) {
          const errorMsg = error.error.message;
          this.errorNotification(errorMsg);
        } else if (error.status === 500) {
          const errorMsg = error.error.message;
          this.errorNotification(errorMsg);
        } else if (error.status === 404) {
          const errorMsg = error.error.message;
          this.errorNotification(errorMsg);
        } else if (error.status === 409) {
          const errorMsg = error.error.message;
          this.errorNotification(errorMsg);
        } else {
          return next.handle(request);
        }
      })
    );
  }

  successNotification(msg: string) {
    this.toastr.success('Success!', msg);
  }

  errorNotification(msg: string) {
    this.toastr.error('Error!', msg);
  }
}
