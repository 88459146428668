import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import { Allowance } from './../../../../models/allowance';
import {DeductionService} from '../../../../services/deduction.service';
import {AddAllowanceComponent} from '../add-allowance/add-allowance.component';
import {EditAllowanceComponent} from '../edit-allowance/edit-allowance.component';
@Component({
  selector: 'app-allowance',
  templateUrl: './allowance.component.html',
  styleUrls: ['./allowance.component.css']
})
export class AllowanceComponent implements OnInit {


  private unsubscribe = new Subject();
  isLoaded = false;
  members:any;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<Allowance> | undefined;
  dataSource: MatTableDataSource<Allowance> | undefined;

  displayedColumns = ['name', 'category', 'type','amount', 'percent',
   'description','actions'];

  constructor(
    public dialog: MatDialog,
    private deductionService: DeductionService
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchAllowances();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(AddAllowanceComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllowances();
      }
    });
  }

  onEdit(allowance: Allowance): void {
    const config: MatDialogConfig = {
      data: {
       allowance
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(EditAllowanceComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllowances();
      }
    });
  }

  onDelete(allowance: Allowance) {
    const id = allowance.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.deductionService.deleteAllowance({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllowances();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  fetchAllowances() {
    this.deductionService.getAllowances().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res.data;
        
        this.isLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }


}
