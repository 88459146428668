import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ViewMandatoryContributionsComponent} from './view-mandatory-contributions/view-mandatory-contributions.component';

const routes: Routes = [
  {
    path: '',
    component: ViewMandatoryContributionsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MandatoryContributionsRoutingModule {
}
