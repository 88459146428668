<ng-container *ngIf="isLoaded">
    <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
      
      <div class="mat-elevation-z6 table-responsive" style="margin-top: 30px">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Name"> {{row.name || 'N/A'}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="from">
            <mat-header-cell *matHeaderCellDef mat-sort-header> From Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="FromAmount"> {{row.from | mask: 'separator':','}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="to">
            <mat-header-cell *matHeaderCellDef mat-sort-header> To Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="to"> {{row.to | mask: 'separator':','}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tax_rate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Tax Rate</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="tax_rate"> {{row.tax_rate}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tax_plus_amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Excess Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="tax_plus_amount"> {{row.tax_plus_amount | mask: 'separator':','}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="description"> {{row.description || 'N/A'}} </mat-cell>
          </ng-container>
  
         
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
        <mat-paginator #paginator
                       [pageIndex]="0"
                       [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                       [pageSize]="10"
                       [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  
   
  </ng-container>
  