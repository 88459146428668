import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {AddEmployeeComponent} from '../add-employee/add-employee.component';
import {EditEmployeeComponent} from '../edit-employee/edit-employee.component';
import {Employees} from '../../../../models/employees';
import {EmployeesService} from '../../../../services/employees.service';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-view-employees',
  templateUrl: './view-employees.component.html',
  styleUrls: ['./view-employees.component.css']
})
export class ViewEmployeesComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatTable) table: MatTable<Employees> | undefined;
  dataSource: MatTableDataSource<Employees> | undefined;

  displayedColumns = [
    'id',
    'full_name',
    'phone_no',
    'email',
    'salary',
    'status',
    'created_at',
    'actions'
  ];

  constructor(public dialog: MatDialog, private employeesService: EmployeesService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchAllEmployees();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '1200px'
    };
    const dialogRef = this.dialog.open(AddEmployeeComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllEmployees();
      }
    });
  }

  onEdit(emp: Employees): void {
    const config: MatDialogConfig = {
      data: {
        emp
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '1200px'
    };
    const dialogRef = this.dialog.open(EditEmployeeComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllEmployees();
      }
    });
  }

  onDelete(emp: Employees) {
    const id = emp.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.employeesService.deleteEmployee({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllEmployees();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  // tslint:disable-next-line:typedef
  fetchAllEmployees() {
    this.employeesService.getEmployees().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
