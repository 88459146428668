import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../../../auth/models/user';
import {CompanyService} from '../../../../services/company.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  submitForm: any;
  click = false;
  roles: any = [];
  companies: any = [];
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public dialogRef: MatDialogRef<EditUserComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchRoles();
    this.fetchCompanies();
  }

  SubmitData() {
    const phoneNo = '0[0-9]{9}';
    const userData = this.data.user;
    const first_name = userData.first_name;
    const last_name = userData.last_name;
    const gender = userData.gender;
    const phoneNumber = userData.phone_no;
    const email = userData.email;
    const role = userData.role;
    const comID = userData.com_id;

    this.submitForm = new FormGroup({
      first_name: new FormControl(first_name, [Validators.required]),
      last_name: new FormControl(last_name, [Validators.required]),
      gender: new FormControl(gender, [Validators.required]),
      phone_no: new FormControl(phoneNumber, [Validators.required, Validators.pattern(phoneNo)]),
      email: new FormControl(email, [Validators.required]),
      role: new FormControl(role, [Validators.required]),
      com_id: new FormControl(comID, [Validators.required])
    });
  }

  fetchRoles() {
    this.userService.getRoles().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        this.roles = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchCompanies() {
    this.companyService.getCompanies().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        this.companies = res;
      }, (error: HttpErrorResponse) => {

      });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const userData = this.data.user;
    const Id = userData.id;

    values = {
      ...values,
      id: Id
    };
    this.userService.updateUser(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
