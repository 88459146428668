import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Dashboard} from '../models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends DefaultDataService<Dashboard> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Dashboard', http, httpUrlGenerator);
  }

  getStatics(): Observable<Dashboard[]> {
    const url = `${(this.API_BASEURL)}/statistics`;
    return this.http.get<Dashboard[]>(url);
  }
  getChartData(): Observable<any> {
    const url = `${(this.API_BASEURL)}/chart`;
    return this.http.get<any>(url);
  }
}
