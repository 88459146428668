<app-custom-dialog>
    <div header>Add Pension Fund</div>
    <div body>
      <form [formGroup]="submitForm" autocomplete="off">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Pension Name</mat-label>
          <input formControlName="name"  matInput placeholder=""/>
         
          <mat-error *ngIf="submitForm.get('name').hasError('required')">
            Field is required
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="w-100">
          <mat-label>Select Pension Fund</mat-label>
          
          <mat-select formControlName="refer_pension_id">
            <mat-option value="1">PSPF</mat-option>
            <mat-option value="2">NSSF</mat-option>
            <mat-option value="3">LAPF</mat-option>
            <mat-option value="4">GEPF</mat-option>
          </mat-select>
          <mat-error *ngIf="submitForm.get('refer_pension_id').hasError('required')">
            Field is required
          </mat-error>
        </mat-form-field> -->
       
       
      
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Employer Percent contribution</mat-label>
          <input formControlName="employer_percentage" type="number" matInput placeholder="" />
          <mat-error *ngIf="submitForm.get('employer_percentage').hasError('required')">
            Field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Employee Percent contribution</mat-label>
          <input formControlName="employee_percentage" type="number"  matInput placeholder="" />
          <mat-error *ngIf="submitForm.get('employee_percentage').hasError('required')">
            Field is required
          </mat-error>
        </mat-form-field>
     
        
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Select Status</mat-label>
            
            <mat-select formControlName="status">
              <mat-option value="0">Based on Gross Salary</mat-option>
              <mat-option value="1">Based on Basic Salary</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('status').hasError('required')">
                Field is required
              </mat-error>
          </mat-form-field>
         
  
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Description</mat-label>
          <textarea formControlName="address" matInput placeholder="Ex. Pension details..."></textarea>
          <mat-error *ngIf="submitForm.get('address').hasError('required')">
            Field is required
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="custom-action-buttons" footer>
      <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
      <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
    </div>
  </app-custom-dialog>
  