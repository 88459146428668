import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {ResidentialStatus} from '../models/residential-status';

@Injectable({
  providedIn: 'root'
})
export class ResidentialStatusService extends DefaultDataService<ResidentialStatus> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('ResidentialStatus', http, httpUrlGenerator);
  }

  getResidentialStatus(): Observable<ResidentialStatus[]> {
    const url = `${(this.API_BASEURL)}/residential-status`;
    return this.http.get<ResidentialStatus[]>(url);
  }

  addResidentialStatus(body: any): Observable<ResidentialStatus> {
    const url = `${this.API_BASEURL}/add-residential-status`;
    return this.http.post<ResidentialStatus>(url, body);
  }

  updateResidentialStatus(body: any): Observable<ResidentialStatus> {
    const url = `${this.API_BASEURL}/update-residential-status/${body.id}`;
    return this.http.put<ResidentialStatus>(url, body);
  }

  deleteResidentialStatus(body: any) {
    const url = `${this.API_BASEURL}/delete-residential-status/${body.id}`;
    return this.http.delete(url, body);
  }
}
