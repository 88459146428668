import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public loggedUser: any;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.grabUserData();
  }

  grabUserData() {
    // tslint:disable-next-line:variable-name
    const user_data = localStorage.getItem('core-user');
    if (typeof user_data === 'string') {
      const userData = atob(user_data);
      const userDetails = JSON.parse(userData);
      this.loggedUser = userDetails;
    }
  }


  goMCPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/mandatory-contributions']);
    });
  }

  goDeductionPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/deductions']);
    });
  }

  goBandPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/bands']);
    });
  }

  goRSPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/residential-status']);
    });
  }

  goFYPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/financial-year']);
    });
  }
}
