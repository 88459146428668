import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PayrollRoutingModule} from './payroll-routing.module';
import {PayrollComponent} from './payroll.component';
import {SharedModule} from '../../../shared/shared.module';
import {EmployeesModule} from './employees/employees.module';
import {GeneratePayrollModule} from './generate-payroll/generate-payroll.module';


@NgModule({
  declarations: [
    PayrollComponent
  ],
  imports: [
    CommonModule,
    PayrollRoutingModule,
    SharedModule,
    EmployeesModule,
    GeneratePayrollModule
  ]
})
export class PayrollModule {
}
