import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {GeneratePayrollRoutingModule} from './generate-payroll-routing.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ViewPayrollComponent} from './view-payroll/view-payroll.component';
import {EmployeePayslipComponent} from './employee-payslip/employee-payslip.component';
import {GeneratePayrollComponent} from './generate-payroll/generate-payroll.component';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { PayrollSummaryComponent } from './payroll-summary/payroll-summary.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },

};

@NgModule({
  declarations: [
    ViewPayrollComponent,
    EmployeePayslipComponent,
    GeneratePayrollComponent,
    PayrollSummaryComponent
  ],
  imports: [
    CommonModule,
    GeneratePayrollRoutingModule,
    SharedModule
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    DatePipe
  ],
})
export class GeneratePayrollModule {
}
