import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {BandService} from '../../../../services/band.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Band} from '../../../../models/band';
import {FinancialYearService} from '../../../../services/financial-year.service';

@Component({
  selector: 'app-edit-band',
  templateUrl: './edit-band.component.html',
  styleUrls: ['./edit-band.component.css']
})
export class EditBandComponent implements OnInit {

  submitForm: any;
  click = false;
  fys: any = [];
  private unsubscribe = new Subject();

  constructor(
    private bandService: BandService,
    private financialYearService: FinancialYearService,
    @Inject(MAT_DIALOG_DATA) public data: { band: Band },
    public dialogRef: MatDialogRef<EditBandComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchFinancialYears();
  }

  SubmitData() {
    const itemData = this.data.band;
    const name = itemData.name;
    const maxAmount = itemData.amount;
    const rate = itemData.rate;
    const fyID = itemData.fy_id;

    this.submitForm = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      amount: new FormControl(maxAmount, [Validators.required]),
      rate: new FormControl(rate, [Validators.required]),
      fy_id: new FormControl(fyID, [Validators.required])
    });
  }

  fetchFinancialYears() {
    this.financialYearService.getFinancialYears().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.fys = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.band;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.bandService.updateBand(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
