import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {CompanyService} from '../../../../services/company.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Company} from '../../../../models/company';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  submitForm: any;
  click = false;
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company },
    public dialogRef: MatDialogRef<EditCompanyComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    const itemData = this.data.company;
    const name = itemData.name;
    const address = itemData.address;
    const phoneNumber = itemData.phone_number;
    const email = itemData.email;
    const employees = itemData.employees;

    this.submitForm = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      address: new FormControl(address, [Validators.required]),
      phone_number: new FormControl(phoneNumber, [Validators.required]),
      email: new FormControl(email, [Validators.required]),
      employees: new FormControl(employees, [Validators.required])
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.company;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    // this.companyService.updateCompany(Id,values).pipe(takeUntil(this.unsubscribe)).subscribe(
    //   (response: any) => {
    //     this.dialogRef.close('updated');
    //   },
    //   (error: HttpErrorResponse) => {
    //     this.click = false;
    //   }
    // );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
