<app-custom-dialog>
  <div header>Update Employee</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <div class="row">
        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Job Title</mat-label>
            <input formControlName="title" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('title').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>FullName</mat-label>
            <input formControlName="full_name" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('full_name').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('gender').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone Number</mat-label>
            <input formControlName="phone_no" mask="0000000000" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('phone_no').hasError('required')">
              Field is required
            </mat-error>
            <mat-error *ngIf="submitForm.get('phone_no').hasError('pattern')">
              Phone number should have 10 digits and start with 0 (eg. 0713000000)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('email').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Address</mat-label>
            <input formControlName="address" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('address').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Residential Status</mat-label>
            <mat-select formControlName="res_id">
              <mat-option value="1">Residential </mat-option>
              <mat-option value="0">Non Residential </mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('res_id').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Basic Salary</mat-label>
            <input formControlName="basic_salary" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
            <mat-error *ngIf="submitForm.get('basic_salary').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Allowance</mat-label>
            <input formControlName="allowance" mask="separator.0" matInput placeholder="" thousandSeparator=","/>
            <mat-error *ngIf="submitForm.get('allowance').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div> -->

        <!-- <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tax Band</mat-label>
            <mat-select formControlName="bid">
              <mat-option *ngFor="let band of bands"
                          [value]="band.id">{{ band.name }} - {{ band.amount | mask: 'separator':',' }}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('bid').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div> -->

        <!-- <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>HESLB Loan</mat-label>
            <mat-select formControlName="loan">
              <mat-option value="1">YES</mat-option>
              <mat-option value="0">NO</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('loan').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div> -->

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Bank Name</mat-label>
            <input formControlName="bank_name" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('bank_name').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Account Name</mat-label>
            <input formControlName="bank_ac_name" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('bank_ac_name').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Account Number</mat-label>
            <input formControlName="bank_ac_no" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('bank_ac_no').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>TIN Number</mat-label>
            <input formControlName="tin_no" mask="separator.0" matInput placeholder="" thousandSeparator=""/>
        
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Social Security Number</mat-label>
            <input formControlName="ssn" matInput placeholder=""/>
          
          </mat-form-field>
        </div>

        <div class="col-lg-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option value="ACTIVE">ACTIVE</mat-option>
              <mat-option value="INACTIVE">INACTIVE</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('status').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div>

      </div>
      <!-- <br/>
      <span class="title-1">DEDUCTIONS</span>
      <hr/>
      <br/>
      <div class="row">
        <div class="col-lg-1" *ngIf="deductions?.includes('NSSF')">
          <mat-checkbox class="title-2" color="primary" formControlName="is_nssf" [checked]="this.myNssf > 0">NSSF
          </mat-checkbox>
        </div>
        <div class="col-lg-1" *ngIf="deductions?.includes('NHIF')">
          <mat-checkbox class="title-2" color="primary" formControlName="is_nhif" [checked]="this.myNhif > 0">NHIF
          </mat-checkbox>
        </div>
      </div> -->
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
