import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinancialYearRoutingModule } from './financial-year-routing.module';
import { AddFinancialYearComponent } from './add-financial-year/add-financial-year.component';
import { EditFinancialYearComponent } from './edit-financial-year/edit-financial-year.component';
import { ViewFinancialYearComponent } from './view-financial-year/view-financial-year.component';
import {SharedModule} from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    AddFinancialYearComponent,
    EditFinancialYearComponent,
    ViewFinancialYearComponent
  ],
  imports: [
    CommonModule,
    FinancialYearRoutingModule,
    SharedModule
  ]
})
export class FinancialYearModule { }
