<app-custom-dialog>
 
  <div header>Employee Payslip</div>
  <div body>
    <div id="print-payslip">
      
    <div class="row">
      <div class="col-lg-4">   
        <img src="{{imgDirectory + companyData?.photo}}  " style="width:50%; height:50%;" 
        >          
      </div>
      <div class="col-lg-8" style="font-family:tahoma; font-size:15px;">
        <div class="letterhead" style="align-items: center; position: relative; justify-content:center;">
          <li style="list-style: none;"> <b  style="font-family:tahoma;font-size:20px; display:block; text-transform: capitalize;">{{companyData?.name}}</b></li>
        
          <li style="list-style: none;">Address:{{companyData?.address}}</li>
          <li style="list-style: none;">Mobile no: {{companyData?.phone_number}}</li>
          <li style="list-style: none;">Email:{{companyData?.email}}</li>
          <li style="list-style: none;">Website:{{companyData?.website}}</li>
         
</div>
     
    

        </div> 
        
        <!-- <div class="col-lg-2">
            <h2 style="font-weight:bold; text-transform:uppercase">PaySlip</h2>
        </div> -->
        
    </div>
   <br><br>
   <p>Payslip For: {{payrollData.salary.month_year }}</p>
      <table class="table table-bordered title-1">
        <thead>
        <tr class="title-1">
          <th>Employee Name</th>
          <td>{{payrollData.salary?.full_name}}</td>
        </tr>
        <tr class="title-1">
          <th>Title</th>
          <td>{{payrollData.employee?.title}}</td>
        </tr>
        <tr>
          <th colspan="2" class="title-2">PAYMENTS</th>
        </tr>
        <tr class="title-1">
          <td>Bank</td>
          <td>{{payrollData.employee?.bank_name}}</td>
        </tr>
        <tr class="title-1">
          <td>Account Number</td>
          <td>{{payrollData.salary?.account_no}}</td>
        </tr>
       
        <tr>
          <th colspan="2" class="title-2">TOTAL EARNINGS</th>
        </tr>
        <tr class="title-1">
          <th>Basic Salary</th>
          <td>{{payrollData.salary.basic_salary | mask: 'separator':','}}</td>
        </tr>
        <tr class="title-1">
          <th>Taxable Earnings</th>
          <td>{{payrollData.salary.taxable_pay | mask: 'separator':','}}</td>
        </tr>
        <tr class="title-1">
          <th>Allowance</th>
          <td>{{payrollData.salary.total_allowance | mask: 'separator':','}}</td>
        </tr>
        <tr class="title-1">
          <th>Gross Amount Payable</th>
          <td>{{payrollData.salary.gross_pay | mask: 'separator':','}}</td>
        </tr>
        <tr>
          <th colspan="2" class="title-2">PENSION FUNDS</th>
        </tr>
        <tr class="title-1">
          <th>Social Security</th>
          <td>{{payrollData.employee?.ssn}}</td>
        </tr>
        <tr class="title-1">
          <th>Total Pension Funds</th>
          <td>{{payrollData.salary.total_pension| mask: 'separator':','}}</td>
        </tr>
        <tr>
          
          <th colspan="2" class="title-2">DEDUCTIONS</th>
        </tr>
        
          <tr *ngFor="let item of payrollData.payslip">
            <th>{{item.name}}</th>
            <td>{{(item?.amount || ((payrollData.salary.basic_salary) *item?.percent/100)) | mask: 'separator':','}}  </td>
            
          </tr>
          
        
       
        <tr class="title-1">
          <th>PAYE</th>
          <td>{{payrollData.salary.total_paye | mask: 'separator':','}}</td>
        </tr>
        <tr class="title-1">
          <th>Total Deductions</th>
          <td>{{payrollData.salary.total_deduction | mask: 'separator':','}}</td>
        </tr>
        <tr class="title-1">
          <th>Net Amount Payable</th>
          <td>{{payrollData.salary.total_net_pay | mask: 'separator':','}}</td>
        </tr>
        </thead>
      </table>
      <p align="center" style="font-weight:bold ;font-size:18px;text-transform: uppercase;">{{companyData?.slogan}}</p>
    </div>
  </div>
  <div class="custom-action-buttons" footer>
    <button color="primary" printSectionId="print-payslip" [useExistingCss]="true" ngxPrint mat-raised-button>PRINT
      PAYSLIP
    </button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
