import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PensionRoutingModule } from './pension-routing.module';
import { PensionComponent } from '././view-pension/pension.component';
import {SharedModule} from '../../../../shared/shared.module';
import { AddPensionComponent } from './add-pension/add-pension.component';
import { EditPensionComponent } from './edit-pension/edit-pension.component';
import { MemberComponent } from './member/member.component';
@NgModule({
  declarations: [
    PensionComponent,
    AddPensionComponent,
    EditPensionComponent,
    MemberComponent
  ],
  imports: [
    CommonModule,
    PensionRoutingModule,
    SharedModule
  ]
})
export class PensionModule { }
