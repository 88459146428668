import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MainlayoutRoutingModule} from './mainlayout-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { AvatarComponent } from './avatar/avatar.component';


@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    AvatarComponent
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    AvatarComponent
  ],
  imports: [
    CommonModule,
    MainlayoutRoutingModule,
    SharedModule
  ]
})
export class MainlayoutModule {
}
