import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Taxes} from '../../../models/tax';
import {BandService} from '../../../services/band.service';
@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit {
  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<Taxes> | undefined;
  dataSource: MatTableDataSource<Taxes> | undefined;

  displayedColumns = ['name', 'from', 'to','tax_rate','tax_plus_amount','description'];

  constructor( private bandService: BandService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchTaxes();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  fetchTaxes() {
    this.bandService.getTaxes().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res;
        this.isLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
}
