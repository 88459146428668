import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../../../../services/user.service';
import {CompanyService} from '../../../../services/company.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  submitForm: any;
  click = false;
  roles: any = [];
  companies: any = [];
  private unsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<AddUserComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
    this.fetchRoles();
    this.fetchCompanies();
  }

  SubmitData() {
    const phoneNo = '0[0-9]{9}';
    this.submitForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      phone_no: new FormControl('', [Validators.required, Validators.pattern(phoneNo)]),
      email: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    
    });
  }

  fetchRoles() {
    this.userService.getRoles().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        this.roles = res;
      }, (error: HttpErrorResponse) => {
      });
  }

  fetchCompanies() {
    this.companyService.getCompanies().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        this.companies = res;
      }, (error: HttpErrorResponse) => {

      });
  }

  onSave() {
    this.click = true;
    const values = this.submitForm.value;
    this.userService.addUser(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
