import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ViewHolidaysComponent} from './view-holidays/view-holidays.component';

const routes: Routes = [
  {
    path: '',
    component: ViewHolidaysComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HolidayRoutingModule {
}
