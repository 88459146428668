<div class="profile-content">
  <div class="row gutters-sm">
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
             <img alt="Admin" class="" src="./assets/imgs/icons/user.png"
                 width="150" *ngIf="userData.photo === null">
                 <img src="{{imgDirectory + userData?.photo}}"   width="150" *ngIf="userData.photo !== null"/>
            <div class="mt-3">
              <h3 class="profile-content">{{userData?.first_name || "N/A"}} {{userData?.last_name || "N/A"}}</h3>
              <p class="text-secondary mb-1 profile-content">{{userData?.role || "N/A"}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <h3 class="mb-0 profile-content">Full Name</h3>
            </div>
            <div class="col-sm-9 text-secondary">
              {{userData?.first_name || "N/A"}} {{userData?.last_name || "N/A"}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-3">
              <h3 class="mb-0 profile-content">Gender</h3>
            </div>
            <div class="col-sm-9 text-secondary">
              {{userData?.gender || "N/A"}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-3">
              <h3 class="mb-0 profile-content">Email</h3>
            </div>
            <div class="col-sm-9 text-secondary">
              {{userData?.email || "N/A"}}
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-3">
              <h3 class="mb-0 profile-content">Phone Number</h3>
            </div>
            <div class="col-sm-9 text-secondary">
              {{userData?.phone_no || "N/A"}}
            </div>
          </div>
          <div class="modal-footer">
            <button (click)="onEdit(userData)" class="" color="primary" mat-button>
              <mat-icon>edit</mat-icon>
              EDIT
            </button>
            <button (click)="onPasswordChange(userData)" [mat-dialog-close]="true" color="primary" mat-button>
              <mat-icon>lock</mat-icon>
              CHANGE PASSWORD
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
