import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ViewCompaniesComponent} from './view-companies/view-companies.component';

const routes: Routes = [
  {
    path: '',
    component: ViewCompaniesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
