import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {FinancialYear} from '../models/financial-year';

@Injectable({
  providedIn: 'root'
})
export class FinancialYearService extends DefaultDataService<FinancialYear> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('FinancialYear', http, httpUrlGenerator);
  }

  getFinancialYears(): Observable<FinancialYear[]> {
    const url = `${(this.API_BASEURL)}/financial-year`;
    return this.http.get<FinancialYear[]>(url);
  }

  addFinancialYear(body: any): Observable<FinancialYear> {
    const url = `${this.API_BASEURL}/add-financial-year`;
    return this.http.post<FinancialYear>(url, body);
  }

  updateFinancialYear(body: any): Observable<FinancialYear> {
    const url = `${this.API_BASEURL}/update-financial-year/${body.id}`;
    return this.http.put<FinancialYear>(url, body);
  }

  changeFinancialYear(body: any): Observable<FinancialYear> {
    const url = `${this.API_BASEURL}/change-financial-year-status/${body.id}`;
    return this.http.put<FinancialYear>(url, body);
  }

  deleteFinancialYear(body: any) {
    const url = `${this.API_BASEURL}/delete-financial-year/${body.id}`;
    return this.http.delete(url, body);
  }
}
