



 
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
   
      <div class="col-lg-6 cont d-lg-flex d-none position-absolute end-0 justify-content-center flex-column">
        <div style="position:absolute;top:2% !important;">
          <img height="50%" src="https://bizzyn.com/wp-content/uploads/2022/07/Asset-3.png" width="30%"
          >
        </div>
        
        <img src="assets/imgs/icons/payroll.png" height="50%" width="70%" alt="" style="margin-left: 11%;">
      </div>

      <div class="col-lg-6 cont2 d-flex flex-column mx-lg-0 mx-auto end-0 justify-content-center pe-0">
        <div class="main-d">
  
            <div class="login-root">
                <div class="box-root flex-flex flex-direction--column">
                    <div class="box-root padding-top--24 flex-flex flex-direction--column" style="flex-grow: 1; z-index: 9;">
                       
                        <h1 style="text-align: center; padding:10px;font-weight: 400;" class="text-primary">Register Today and Start Growing your <br> Business with Bizzyn.</h1>
                        <div class="">
                            <div class="formbg">
                                <div class="formbg-inner padding-horizontal--48">
                                    <!-- <span class="padding-bottom--15" style="text-align: center; margin-bottom: 20px;">Register</span> -->          
                                    <form [formGroup] = "formData" (ngSubmit)="register()" autocomplete="off">
                                        <div class="form-slip">
                                            <div class="padding-bottom--24 pr-2">
                                                <label for="email">Company Name</label>
                                                <input class="form-control" type="text" name="company"  required formControlName='company'>
                                            </div>
                                            <div class="padding-bottom--24 pl-2">
                                                <label for="email">Address</label>
                                                <input class="form-control" type="text" name="company" formControlName='address'>
                                            </div>
                                        </div>  
                                        <!-- Selection oprion for Countries -->
                                        <div class="form-slip">
                                            <div class="padding-bottom--24 pr-2">
                                                <label for="email">Email</label>
                                                <input class="form-control" type="text" name="email" required formControlName='email'>
                                            </div>
                                            <div class="padding-bottom--24 pl-2">
                                                <label for="phone">Phone Number &nbsp;
                                                  <span *ngIf="phoneError" style="font-size:10px;display:inline;color: red;padding-right:48px;" class="pull-right">{{phoneError}}</span>
                                                 </label>                                
                                                <input class="form-control" type="tel" id="phone" name="phone" formControlName='phone' (keyup)="validateNumber()"
                                                 required>                                   
                                            </div>
                                        </div> 
                                        <div class="form-slip">
                                            <div class="padding-bottom--24 pr-2">
                                                <div class="grid--50-50">
                                                    <label for="password">Password</label>
                                                </div>
                                                <input class="form-control" type="password" name="password" formControlName='password'>
                                            </div>
          
                                            <div class="padding-bottom--24 pl-2">
                                                <div class="grid--50-50">
                                                    <label for="password2">Confirm Password</label>
                                                </div>
                                                <input class="form-control" required type="password" name="password" formControlName='confirmpwd'>
                                            </div>
                                        </div>  
                                    
                                        <div class="field">                      
                                            <button id="submit" type="submit" class="btn btn-primary btn-extended" [disabled]="formData.invalid">Register</button>                         
                                            <button class="btn btn-primary btn-extended" *ngIf="processing" [disabled]="true">Relax while we are preparing your working environment....</button>
                                        </div>
                                        <div class="bottom">
                                            <p>Already have an Account? <a href="javascipt:" routerLink="/" style="color:#1a3765">Login</a></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="hints animate__animated animate__fadeInDown" *ngIf="alert && alert.msg" [ngClass]="msgClasses">
              <p>{{alert.msg}}</p>
            </div>
          </div>
     
      </div>
    </div>
  </div>
 

