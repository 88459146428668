import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FinancialYearService} from '../../../../services/financial-year.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {FinancialYear} from '../../../../models/financial-year';

@Component({
  selector: 'app-edit-financial-year',
  templateUrl: './edit-financial-year.component.html',
  styleUrls: ['./edit-financial-year.component.css']
})
export class EditFinancialYearComponent implements OnInit {

  submitForm: any;
  click = false;
  private unsubscribe = new Subject();

  constructor(
    private financialYearService: FinancialYearService,
    @Inject(MAT_DIALOG_DATA) public data: { fy: FinancialYear },
    public dialogRef: MatDialogRef<EditFinancialYearComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    const itemData = this.data.fy;
    const year = itemData.year;

    this.submitForm = new FormGroup({
      year: new FormControl(year, [Validators.required])
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.fy;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.financialYearService.updateFinancialYear(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
