import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  goBack() {
    // tslint:disable-next-line:variable-name
    const user_data = localStorage.getItem('core-user');
    // @ts-ignore
    const userData = atob(user_data);
    const userDetails = JSON.parse(userData);
    if (localStorage.getItem('core-user')) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['core']);
      });
    } else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/']);
      });
    }
  }

}
