import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RolesPermissionsRoutingModule } from './roles-permissions-routing.module';
import {SharedModule} from "../../../../shared/shared.module";
import {ViewRoleComponent} from "./view-role/view-role.component";
import {AddRoleComponent} from "./add-role/add-role.component";
import {EditRoleComponent} from "./edit-role/edit-role.component";


@NgModule({
  declarations: [
    ViewRoleComponent,
    AddRoleComponent,
    EditRoleComponent
  ],
  imports: [
    CommonModule,
    RolesPermissionsRoutingModule,
    SharedModule
  ]
})
export class RolesPermissionsModule { }
