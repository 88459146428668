<ng-container *ngIf="isLoaded">
    <div *ngIf="dataSource && dataSource.data.length > 0; else noData">
      <div class="row">
        <div class="col-md-9">
          <button (click)="onAddNew()" class="custom-btn" color="primary" mat-raised-button>
            <mat-icon>add</mat-icon>
            Add Allowance
          </button>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Search"/>
          </mat-form-field>
        </div>
      </div>
      <div class="mat-elevation-z6 table-responsive" style="margin-top: 30px">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Name"> {{row.name || 'N/A'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Category</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Category"> {{row.category == 1 ? 'Fixed Allowance' :'Monthly Allowance' || 'N/A'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Allowance Type</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Allowance Type"> {{row.type == 1 ? 'Taxable Allowance' :'Non-Taxable Allowance' || 'N/A'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Amount</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Amount"> {{row.amount | mask: 'separator':',' }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="percent">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Percent</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="percent"> {{row.percent || 'N/A'}}</mat-cell>
          </ng-container>
         
         
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Description"> {{row.description || 'N/A'}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Actions">
              <button [matMenuTriggerFor]="menu" aria-label="More Options" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a [routerLink]="['/core/settings/allowance/members', row.id]">
                  <button  aria-label="edit icon" color="primary"
                  mat-menu-item title="Members">
                 <mat-icon style="color: gray">perm_identity</mat-icon>
                <span>Members</span>
               </button>
                </a>
                
                <button (click)="onEdit(row)" aria-label="edit icon" color="primary"
                        mat-menu-item title="Edit">
                  <mat-icon style="color: gray">edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button (click)="onDelete(row)" aria-label="delete icon" color="primary" mat-menu-item
                        title="Delete">
                  <mat-icon style="color: red">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
        <mat-paginator #paginator
                       [pageIndex]="0"
                       [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 150, 250]"
                       [pageSize]="10"
                       [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>
  
    <ng-template #noData>
      <app-norecords></app-norecords>
      <br/>
      <div align="center">
        <button (click)="onAddNew()" class="" color="primary" mat-button style="font-size: 20px">
          ADD ALLOWANCES
        </button>
      </div>
    </ng-template>
  </ng-container>
  