import { Component, ViewChild, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddPensionComponent} from '../add-pension/add-pension.component';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import { Pension } from 'src/app/modules/core/models/pension';
import { EditPensionComponent } from '../edit-pension/edit-pension.component';
import {DeductionService} from '../../../../services/deduction.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pension',
  templateUrl: './pension.component.html',
  styleUrls: ['./pension.component.css']
})
export class PensionComponent implements OnInit {
  private unsubscribe = new Subject();
  isLoaded = false;
  members:any;
  final:any;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<Pension> | undefined;
  dataSource: MatTableDataSource<Pension> | undefined;

  displayedColumns = ['name', 'employer_percentage','employee_percentage', 'address', 'actions'];
  constructor(public dialog: MatDialog,
    private deductionService: DeductionService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchPension();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  fetchPension() {
    this.deductionService.fetchPensions().pipe(takeUntil(this.unsubscribe)).subscribe(
      (res: any) => {
        // @ts-ignore
        this.dataSource.data = res.data;
     
        this.isLoaded = true;
   
      }, (error: HttpErrorResponse) => {
        this.isLoaded = false;
      });
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(AddPensionComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchPension();
      }
    });
  }

  onEdit(pension: Pension): void {
    const config: MatDialogConfig = {
      data: {
        pension
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '600px'
    };
    const dialogRef = this.dialog.open(EditPensionComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchPension();
      }
    });
  }

  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        // this.deductionService.deletePension({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
        //   (response: any) => {
           
        //   },
        //   (error: HttpErrorResponse) => {
        //     //
        //   }
        // );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
