<div class="container-fluid">
  <div class="row my-5">
    <div class="col-md-3">
      <mat-card (click)="goMCPage()" class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">checklist</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Mandatory Contribution
          </mat-card-content>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3">
      <mat-card (click)="goDeductionPage()"
                class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">fact_check</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Deductions
          </mat-card-content>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3">
      <mat-card (click)="goBandPage()" class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">assignment</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Bands
          </mat-card-content>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3">
      <mat-card (click)="goRSPage()" class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">person</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Residential Status
          </mat-card-content>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3">
      <mat-card (click)="goFYPage()" class="mat-elevation-z0 clickable mat-card-upper-bg">
        <div class="matcard">
          <mat-card-title class="text-center">
            <span class="material-icons" style="font-size: 80px;text-align: center;color: white;">calendar_month</span>
          </mat-card-title>
          <mat-card-content class="card_content">
            Financial Year
          </mat-card-content>
        </div>
      </mat-card>
    </div>

  </div>
</div>
