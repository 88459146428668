import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {SharedModule} from "../shared/shared.module";
import {LoginComponent} from "./components/login/login.component";
import {RecoverPasswordComponent} from "./components/recover-password/recover-password.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NotificationsInterceptor} from "../../interceptors/notifications.interceptor";
import {AuthService} from "./services/auth.service";
import {EntityDataService} from "@ngrx/data";
import {LoaderInterceptor} from "../../interceptors/loader.interceptor";
import { RegisterComponent } from './components/register/register.component';


@NgModule({
  declarations: [
    LoginComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  providers: [
    AuthService,
    {provide: HTTP_INTERCEPTORS, useClass: NotificationsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ]
})
export class AuthModule {
  constructor(
    entityDataService: EntityDataService,
    // tslint:disable-next-line:no-shadowed-variable
    AuthService: AuthService,
  ) {
    // @ts-ignore
    entityDataService.registerServices('User', AuthService);
  }
}
