import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {DeductionService} from '../../../../services/deduction.service';

@Component({
  selector: 'app-add-deduction',
  templateUrl: './add-deduction.component.html',
  styleUrls: ['./add-deduction.component.css']
})
export class AddDeductionComponent implements OnInit {

  submitForm: any;
  click = false;
  is_percentage:boolean=true;
  private unsubscribe = new Subject();

  constructor(
    private deductionService: DeductionService,
    public dialogRef: MatDialogRef<AddDeductionComponent>
  ) {
  }

  ngOnInit(): void {
    this.SubmitData();
  }

  SubmitData() {
    this.submitForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      category: new FormControl(''),
      amount: new FormControl(null),
      percent: new FormControl(null),
      employer_amount: new FormControl(null),
      employer_percent: new FormControl(null),
      description: new FormControl(''),
      is_percentage :new FormControl('',[Validators.required]),
      status: new FormControl('',[Validators.required]),
      
    });
  }

  onSave() {
    this.click = true;
    const values = this.submitForm.value;
    this.deductionService.addDeduction(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
