import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import intlTelInput from 'intl-tel-input';
import {parsePhoneNumber,isPossiblePhoneNumber,isValidPhoneNumber,validatePhoneNumberLength,AsYouType} from 'libphonenumber-js';

interface Alert {type:string,header:string,msg:string}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public phoneLibrary:any = {asYouType:null};
  private intelInput:any;
  public formData:FormGroup;
  public phoneError:string = "";
  public processing:boolean;
  public msgClasses:any;
  public alert:Alert;
  submitForm: any;
  click = false;
  hide = true;
  isLoggedIn = false;
  phoneno: any;
  fname: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private fb:FormBuilder,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.createForm();
    this.initilizeIntel();
    if (localStorage.getItem('core-user')) {
      this.isLoggedIn = true;
    }
  }
  initilizeIntel(){
    this.intelInput = this.prepraeIntelLib();   
  }
  prepraeIntelLib(){
    const input = document.querySelector("#phone");
    let lib = intlTelInput(input, {
      preferredCountries:["tz","ke","ug","rw","bi","mw","zm"],
      separateDialCode:true,
      initialCountry: "auto",
      geoIpLookup:function(success, failure){
        this.authService.ipRecognition()
        .subscribe(resp=>{
          let countryCode = (resp && resp.country) ? resp.country : "tz";
          success(countryCode);
        });
      }.bind(this),
    });
    return lib;
  }
  validateNumber(){
    let input = this.phone.value;
    let countryData = this.intelInput.getSelectedCountryData();
    let iso = countryData.iso2.toUpperCase();
    let number = new AsYouType(iso).input(input);
    if(isValidPhoneNumber(number,iso)){
      this.phone.setErrors({valid:true})
      this.phone.updateValueAndValidity();
      this.phoneError = "";
    }else{
      this.phone.setErrors({valid:false})
      this.phone.updateValueAndValidity();
      this.phoneError = 'Invalid Number';
    } 
  }
  createForm(){
    this.formData = this.fb.group({
       company:['',Validators.required],
       address:[''],   
       email:['',[Validators.required,Validators.email]],
       phone:['',Validators.required],
       password:['',Validators.required],
       confirmpwd:['',Validators.required]
    })
  }
  //get form fields
  get company(){
    return this.formData.get('company');
  }
  get address(){
    return this.formData.get('address');
  }
  get email(){
    return this.formData.get('email');
  }
  get phone(){
    return this.formData.get('phone');
  }
  get password(){
    return this.formData.get('password');
  }
  get confirmpwd(){
    return this.formData.get('confirmpwd');
  }
  register(){
    if(this.formData.valid){
      if(this.phoneError === "Invalid Number"){
        this.showAlert('warning','Warning','Invalid Phone number !');
        this.msgClasses = {'success':false,'error':true}
      }else{
        if(this.password.value === this.confirmpwd.value){
           this.authService.onRegister(this.formData.value)
           .subscribe((fb:any)=>{
            if(fb.message.email){
              this.showAlert('warning','Warning','Account successfuly created !');
              this.msgClasses = {'success':true,'error':false}
              setTimeout(()=>{
                this.router.navigateByUrl('/');
              },500)
            }    
           },err=>{
            this.showAlert('warning','Warning','Something went wrong !');
            this.msgClasses = {'success':false,'error':true}
           })
        }else{
          this.showAlert('warning','Warning','Password not match !');
          this.msgClasses = {'success':false,'error':true}
        }
      }
    }
  }
  private showAlert(t,h,m):void{     
    this.alert = {type:t,header:h,msg:m}
    setTimeout(()=>{
       this.alert = {type:"",header:"",msg:""};
    },3500)
  }
}