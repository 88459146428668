import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Mandatorycontribution} from '../models/mandatorycontribution';

@Injectable({
  providedIn: 'root'
})
export class MandatorycontributionService extends DefaultDataService<Mandatorycontribution> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Mandatorycontribution', http, httpUrlGenerator);
  }

  getContributions(): Observable<Mandatorycontribution[]> {
    const url = `${(this.API_BASEURL)}/mandatory-contributions`;
    return this.http.get<Mandatorycontribution[]>(url);
  }

  addContribution(body: any): Observable<Mandatorycontribution> {
    const url = `${this.API_BASEURL}/add-mandatory-contribution`;
    return this.http.post<Mandatorycontribution>(url, body);
  }

  updateContribution(body: any): Observable<Mandatorycontribution> {
    const url = `${this.API_BASEURL}/update-mandatory-contribution/${body.id}`;
    return this.http.put<Mandatorycontribution>(url, body);
  }

  deleteContribution(body: any) {
    const url = `${this.API_BASEURL}/delete-mandatory-contribution/${body.id}`;
    return this.http.delete(url, body);
  }
}
