import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator, QueryParams} from '@ngrx/data';
import {Observable} from 'rxjs';
import {User} from '../../auth/models/user';
import {Role} from '../models/role';
import {environment} from '../../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class UserService extends DefaultDataService<User> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('User', http, httpUrlGenerator);
  }

  getAllUsers(): Observable<User[]> {
    const url = `${(this.API_BASEURL)}/users`;
    return this.http.get<User[]>(url);
  }

  addUser(body: any): Observable<User> {
    const url = `${this.API_BASEURL}/add-user`;
    return this.http.post<User>(url, body);
  }

  updateUser(body: any): Observable<User> {
    const url = `${this.API_BASEURL}/update-user/${body.id}`;
    return this.http.put<User>(url, body);
  }

  deleteUser(body: any) {
    const url = `${this.API_BASEURL}/delete-user/${body.id}`;
    return this.http.delete(url, body);
  }

  updateUserStatus(body: any, params: QueryParams): Observable<User> {
    const url = `${this.API_BASEURL}/change_user_status/${params.id}`;
    return this.http.put<User>(url, body);
  }

  getRoles(): Observable<Role[]> {
    const url = `${(this.API_BASEURL)}/roles`;
    return this.http.get<Role[]>(url);
  }
}
