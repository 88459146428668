<div class="modal-dialog">
  <div class="modal-content">
<div class="modal-header header_bg">
  <mat-dialog-content class="modal-title header_title">
    <ng-content select="[header]"></ng-content>
  </mat-dialog-content>
</div>
<mat-dialog-content style="margin-top: 20px;">
  <ng-content select="[body]"></ng-content>
</mat-dialog-content>
<div class="modal-footer">
  <ng-content select="[footer]"></ng-content>
</div>
</div>
</div>
