<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
               mode="side"
               opened
               position="start">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header>
      <div header>
        <button (click)="sidenav.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </app-header>
    <app-breadcrumb></app-breadcrumb>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
