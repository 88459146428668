import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DeductionsRoutingModule} from './deductions-routing.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ViewDeductionsComponent } from './view-deductions/view-deductions.component';
import { AddDeductionComponent } from './add-deduction/add-deduction.component';
import { EditDeductionComponent } from './edit-deduction/edit-deduction.component';
import { MembersComponent } from './members/members.component';


@NgModule({
  declarations: [
    ViewDeductionsComponent,
    AddDeductionComponent,
    MembersComponent,
    EditDeductionComponent
  ],
  imports: [
    CommonModule,
    DeductionsRoutingModule,
    SharedModule
  ]
})
export class DeductionsModule {
}
