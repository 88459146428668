import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MembersComponent } from './members/members.component';
import {ViewDeductionsComponent} from './view-deductions/view-deductions.component';

const routes: Routes = [
  {
    path: '',
    component: ViewDeductionsComponent
  },
  {
    path: 'members/:id',
    component: MembersComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeductionsRoutingModule {
}
