import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CoreGuard implements CanActivate {
  constructor(public router: Router, private authService: AuthService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // tslint:disable-next-line:variable-name
    const user_data = localStorage.getItem('core-user');
    // @ts-ignore
    const userData = atob(user_data);
    const userDetails = JSON.parse(userData);
    const userRole = userDetails.role;

    if (userRole === 'ADMINISTRATOR' || userRole === 'HUMAN_RESOURCE'){
      return true;
    }
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    return false;
  }

}
