<mat-toolbar class="header_content app-toolbar" color="">
  <ng-content select="[header]"></ng-content>
  <span>PAYROLL SYSTEM</span>
  <span class="toolbar-spacer"></span>
  <div [matMenuTriggerFor]="menu" style="cursor: pointer;">
    <span class="avatar avatar-md brround">
      <img src="./assets/imgs/icons/user.png"  alt="Profile-img" 
    class="avatar avatar-md brround me-4" *ngIf="userProfile.photo === null">
  
    <img src="{{imgDirectory + userProfile?.photo}}"    class="avatar avatar-md brround me-4" *ngIf="userProfile.photo !== null"/>
  
  </span>
    <span> {{logged_user.first_name}} {{logged_user.last_name}}</span>&nbsp;&nbsp;&nbsp;
  </div>

  
  <mat-menu #menu="matMenu" class="home-menu">
    <button (click)="goCompanyPage()" class="custom-btn" color="primary" mat-menu-item>
      <mat-icon color="" style="color: grey">person</mat-icon>
      <span>Company Profile</span>
    </button>
    <button (click)="goProfilePage()" class="custom-btn" color="primary" mat-menu-item>
      <mat-icon color="" style="color: grey">person</mat-icon>
      <span>My Profile</span>
    </button>
   
    <button (click)="onLogout()" class="custom-btn" color="primary" mat-menu-item>
      <mat-icon color="" style="color: grey">logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
