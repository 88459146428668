import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Band} from '../models/band';
import {Taxes} from '../models/tax'

@Injectable({
  providedIn: 'root'
})
export class BandService extends DefaultDataService<Band> {

  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Band', http, httpUrlGenerator);
  }

  getBands(): Observable<Band[]> {
    const url = `${(this.API_BASEURL)}/bands`;
    return this.http.get<Band[]>(url);
  }
  getTaxes(): Observable<Taxes[]> {
    const url = `${(this.API_BASEURL)}/get-taxes`;
    return this.http.get<Taxes[]>(url);
  }

  addBand(body: any): Observable<Band> {
    const url = `${this.API_BASEURL}/add-band`;
    return this.http.post<Band>(url, body);
  }

  updateBand(body: any): Observable<Band> {
    const url = `${this.API_BASEURL}/update-band/${body.id}`;
    return this.http.put<Band>(url, body);
  }

  deleteBand(body: any) {
    const url = `${this.API_BASEURL}/delete-band/${body.id}`;
    return this.http.delete(url, body);
  }
}
