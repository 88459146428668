import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment.dev';
import { map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Deduction} from '../models/deduction';

@Injectable({
  providedIn: 'root'
})
export class DeductionService extends DefaultDataService<Deduction>  {
  private API_BASEURL = environment.baseUrl;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Deduction', http, httpUrlGenerator);
  }

  getDeductions(): Observable<Deduction[]>{
    const url = `${(this.API_BASEURL)}/deductions`;
    return this.http.get<Deduction[]>(url);
  }
  getAllowances(): Observable<any>{
    const url = `${(this.API_BASEURL)}/allowances`;
    return this.http.get<any>(url);
  }
  
  getDeduction(id: string): Observable<any> {
    const url = `${this.API_BASEURL}/get-deduction/${id}`;
    return this.http.get(url);
}
  getUserDeduction(id: any): Observable<any> {
    const url = `${this.API_BASEURL}/user-deduction/${id}`;
    return this.http.get(url);
}
getEmpDeduction(id: any): Observable<any> {
  const url = `${this.API_BASEURL}/emp-deduction/${id}`;
  return this.http.get(url);
}
unsubscribeMember(body:any): Observable<any>{
  const url = `${this.API_BASEURL}/delete-member/${body.id}/${body.deduction_id}`;
  return this.http.delete(url,body)
}
getUserPension(id: string): Observable<any> {
  const url = `${this.API_BASEURL}/user-pension/${id}`;
  return this.http.get(url);
}
getUserAllowance(id: string): Observable<any> {
  const url = `${this.API_BASEURL}/user-allowance/${id}`;
  return this.http.get(url);
}
subscribeMember(body:any): Observable<any>{
  const url = `${this.API_BASEURL}/add-member/${body.deduction_id}`;
  return this.http.post(url,body)
}
subscribedMembers(id:number): Observable<any>{
  const url = `${this.API_BASEURL}/subscribe/${id}`;
  return this.http.get(url)
}
  addDeduction(body: any): Observable<Deduction> {
    const url = `${this.API_BASEURL}/add-deduction`;
    return this.http.post<Deduction>(url, body);
  }
  addAllowance(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-allowance`;
    return this.http.post<any>(url, body);
  }
  getAllowance(id: string): Observable<any> {
    const url = `${this.API_BASEURL}/get-allowance/${id}`;
    return this.http.get(url);
}
  subscribeAllowance(body:any): Observable<any>{
    const url = `${this.API_BASEURL}/add-allowance-member/${body.allowance_id}`;
    return this.http.post(url,body)
  }
  unsubscribeAllowance(body:any): Observable<any>{
    const url = `${this.API_BASEURL}/delete-allowance-member/${body.id}`;
    return this.http.delete(url,body)
  }
  addPension(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/add-pension`;
    return this.http.post<Deduction>(url, body);
  }

  updateDeduction(body: any): Observable<Deduction> {
    const url = `${this.API_BASEURL}/update-deduction/${body.id}`;
    return this.http.put<Deduction>(url, body);
  }
  updateAllowance(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/update-allowance/${body.id}`;
    return this.http.put<any>(url, body);
  }

  deleteDeduction(body: any) {
    const url = `${this.API_BASEURL}/delete-deduction/${body.id}`;
    return this.http.delete(url, body);
  }
  deleteAllowance(body: any) {
    const url = `${this.API_BASEURL}/delete-allowance/${body.id}`;
    return this.http.delete(url, body);
  }

  deletePension(body: any) {
    // const url = `${this.API_BASEURL}/delete-deduction/${body.id}`;
    // return this.http.delete(url, body);
  }
  fetchPensions() : Observable<any>{
    const url = `${this.API_BASEURL}/get-pension`;
    return this.http.get(url);
  }
  getPension(id: string): Observable<any> {
    const url = `${this.API_BASEURL}/get-pension/${id}`;
    return this.http.get(url);
}
subscribePension(body:any): Observable<any>{
  const url = `${this.API_BASEURL}/add-pension-member/${body.pension_id}`;
  return this.http.post(url,body)
}

  updatePension(body: any): Observable<any> {
    const url = `${this.API_BASEURL}/update-pension/${body.id}`;
    return this.http.put(url, body);
  }
  unsubscribePension(body:any): Observable<any>{
    const url = `${this.API_BASEURL}/delete-pension-member/${body.id}`;
    return this.http.delete(url,body)
  }

}
