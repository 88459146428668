import { Component, Inject, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {DeductionService} from '../../../../services/deduction.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import { Pension } from './../../../../models/pension';

@Component({
  selector: 'app-edit-pension',
  templateUrl: './edit-pension.component.html',
  styleUrls: ['./edit-pension.component.css']
})
export class EditPensionComponent implements OnInit {
  submitForm: any;
  click = false;
  private unsubscribe = new Subject();
  constructor(
    private deductionService: DeductionService,
    @Inject(MAT_DIALOG_DATA) public data: { pension: Pension },
    public dialogRef: MatDialogRef<EditPensionComponent>
  ) { }

  ngOnInit(): void {
    this.SubmitData();
  }
  SubmitData() {
    const itemData = this.data.pension;
    const name = itemData.name;
    const employer_percentage = itemData.employer_percentage;
    const employee_percentage = itemData.employee_percentage;
    const status = itemData.status;
    const refer_pension_id = itemData.refer_pension_id;
    const address = itemData.address;
  
    this.submitForm = new FormGroup({
    
   
      name: new FormControl(name, [Validators.required]),
      employer_percentage: new FormControl(employer_percentage,[Validators.required]),
      employee_percentage: new FormControl(employee_percentage,[Validators.required]),
      status: new FormControl(status,[Validators.required]),
      refer_pension_id: new FormControl(refer_pension_id),
      address: new FormControl(address,[Validators.required]),
    
    });
  }

  onSave() {
    this.click = true;
    let values = this.submitForm.value;
    const itemData = this.data.pension;
    const Id = itemData.id;

    values = {
      ...values,
      id: Id
    };
    this.deductionService.updatePension(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
