<app-custom-dialog>
  <div header>Change Password</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>New Password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          matInput
          placeholder="Password"
        />
        <mat-icon (click)="hide = !hide" matSuffix
        >{{ hide ? "visibility_off" : "visibility" }}
        </mat-icon>
        <mat-error *ngIf="submitForm.get('password').hasError('required')">
          Field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Confirm Password</mat-label>
        <input
          [type]="hidec ? 'password' : 'text'"
          formControlName="confirm_password"
          matInput
          placeholder="Password"
        />
        <mat-icon (click)="hidec = !hidec" matSuffix
        >{{ hidec ? "visibility_off" : "visibility" }}
        </mat-icon>
        <mat-error *ngIf="submitForm.get('confirm_password').hasError('required')">
          Field is required
        </mat-error>
        <mat-error *ngIf="submitForm.get('confirm_password').hasError('matching')">
          Password must match
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
