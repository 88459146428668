import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import { DeductionService } from 'src/app/modules/core/services/deduction.service';

@Component({
  selector: 'app-add-pension',
  templateUrl: './add-pension.component.html',
  styleUrls: ['./add-pension.component.css']
})
export class AddPensionComponent implements OnInit {
  submitForm: any;
  click = false;
  is_percentage:boolean=true;
  private unsubscribe = new Subject();
  constructor(
    private deductionService: DeductionService,
    public dialogRef: MatDialogRef<AddPensionComponent>
  ) { }

  ngOnInit(): void {
    this.SubmitData();
  }
  SubmitData() {
    this.submitForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      employer_percentage: new FormControl('',[Validators.required]),
      employee_percentage: new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      // refer_pension_id: new FormControl('',[Validators.required]),
      status: new FormControl('',[Validators.required]),
      
    });
  }
  onSave() {
    this.click = true;
    const values = this.submitForm.value;
    this.deductionService.addPension(values).pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.dialogRef.close('updated');
      },
      (error: HttpErrorResponse) => {
        this.click = false;
      }
    );
  }

  

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
