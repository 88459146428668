import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddUserComponent} from '../add-user/add-user.component';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {User} from '../../../../../auth/models/user';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../../../../services/user.service';
import {EditUserComponent} from '../edit-user/edit-user.component';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {

  private unsubscribe = new Subject();
  isLoaded = false;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatTable) table: MatTable<User> | undefined;
  dataSource: MatTableDataSource<User> | undefined;

  displayedColumns = ['id', 'first_name', 'phone_no', 'email', 'role', 'created_at', 'actions'];

  constructor(public dialog: MatDialog, private userService: UserService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchAllUsers();
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }

  onAddNew(): void {
    const config: MatDialogConfig = {
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '1000px'
    };
    const dialogRef = this.dialog.open(AddUserComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllUsers();
      }
    });
  }

  onEdit(user: User): void {
    const config: MatDialogConfig = {
      data: {
        user
      },
      disableClose: true,
      panelClass: 'dialog-responsive',
      width: '1000px'
    };
    const dialogRef = this.dialog.open(EditUserComponent, config);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      // @ts-ignore
      if (result === 'updated') {
        this.fetchAllUsers();
      }
    });
  }

  // tslint:disable-next-line:typedef
  fetchAllUsers(){
    this.userService.getAllUsers().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  onDelete(user: User){
    const id = user.id;
    const msg = 'You\'re about to delete this user';
    this.alertConfirmation(msg, id);
  }

  alertConfirmation(
    msg: string,
    Id: string){
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteUser({ id: Id }).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllUsers();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string){
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
