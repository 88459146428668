import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MainlayoutComponent} from './components/mainlayout/mainlayout.component';
import {CoreGuard} from '../shared/guards/core.guard';

const routes: Routes = [
  {
    canActivate: [CoreGuard],
    path: '',
    component: MainlayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          title: 'Dashboard',
          breadcrumb: 'Dashboard',
        },
      },
      {
        path: 'payroll',
        data: {
          title: 'Payroll Management',
          breadcrumb: 'Payroll Management',
        },
        loadChildren: () => import('./components/payroll/payroll.module').then(m => m.PayrollModule),
      },
      {
        path: 'settings',
        data: {
          title: 'Settings',
          breadcrumb: 'Settings',
        },
        loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'profile',
        data: {
          title: 'My Profile',
          breadcrumb: 'My Profile',
        },
        loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
