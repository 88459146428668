import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { ProfileService } from './../../../services/profile.service';
import {HttpErrorResponse} from '@angular/common/http';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public logged_user: any;
  userProfile:any;
  imgDirectory :any = 'https://bizzyn-pos.s3.us-east-1.amazonaws.com/users/'
  private unsubscribe = new Subject();
  constructor(private router: Router, private profileService:ProfileService) { }

  ngOnInit(): void {
    this.grabUserData();
    this.grabUserDetails();
  }

  grabUserData() {
    // tslint:disable-next-line:variable-name
    const user_data = localStorage.getItem('core-user');
    if (typeof user_data === 'string') {
      const userData = atob(user_data);
      const userDetails = JSON.parse(userData);
      this.logged_user = userDetails;
      
    }
  }
  grabUserDetails() {
    this.profileService.getUserDetails().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.userProfile  = res[0];
    }, (error: HttpErrorResponse) => {
    });
  }
  goProfilePage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/profile']);
    });
  }

  goCompanyPage() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['core/settings/companysettings']);
    });
  }

  // tslint:disable-next-line:typedef
  onLogout() {
    localStorage.removeItem('core-user');
    localStorage.removeItem('access_token');
    this.router.navigateByUrl('/');
  }

}
