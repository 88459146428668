import { Component, OnInit, ViewChild } from '@angular/core';
import {Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Employees} from '../../../../models/employees';
import {EmployeesService} from '../../../../services/employees.service';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';
import { DeductionService } from 'src/app/modules/core/services/deduction.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {
  private unsubscribe = new Subject();
  isLoaded = false;
  Pension:any;
  userPension:any;
  SubMembers:any;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatTable) table: MatTable<Employees> | undefined;
  dataSource: MatTableDataSource<Employees> | undefined;

  displayedColumns = [
    'id',
    'full_name',
    'title',
    'phone_no',
    'email',
    'salary',
    'actions'
  ];
  constructor(
    public dialog: MatDialog, private employeesService: EmployeesService,
    private deductionService: DeductionService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.fetchAllEmployees();
    this.fetchPension();
    this.fetchUserPension();
  }
  ngAfterViewInit() {
    // @ts-ignore
    this.dataSource.sort = this.sort;
    // @ts-ignore
    this.dataSource.paginator = this.paginator;
    // @ts-ignore
    this.table?.dataSource = this.dataSource;
  }


 

  onDelete(emp: Employees) {
    const id = emp.id;
    const msg = 'You\'re about to delete this item';
    this.alertConfirmation(msg, id);
  }

  fetchPension() {
    const id = this.route.snapshot.params['id']
    this.deductionService.getPension(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.Pension = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  fetchUserPension() {
    const id = this.route.snapshot.params['id']

    this.deductionService.getUserPension(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.userPension = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }
  userChecked(ded_id:number){
    let is_found = this.userPension.find(each=>each.employee_id === ded_id);
    return is_found ? true : false
  }
  subscription() {
    const id = this.route.snapshot.params['id']
    this.deductionService.subscribedMembers(id)
    .pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.SubMembers = res;
      console.log(this.SubMembers)
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

 addMember(employee:Employees){
  let is_found = this.userPension.find(each=>each.employee_id === employee.id)
  let values = {};
  const id = employee.id
  const itemData = this.Pension;
  const pension_id = itemData.id;
  
  values = {
    employee_id: id,
    pension_id:pension_id,
  
  };
  if(is_found){
    
    Swal.fire({
      text: 'You\'re about to delete this item',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.deductionService.unsubscribePension({id: id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllEmployees();
         
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  
}else{
  this.deductionService.subscribePension(values).pipe(takeUntil(this.unsubscribe)).subscribe(
    (response: any) => {
 
    },
    (error: HttpErrorResponse) => {
     
    }
  );
}
 }
  alertConfirmation(
    msg: string,
    Id: string) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.employeesService.deleteEmployee({id: Id}).pipe(takeUntil(this.unsubscribe)).subscribe(
          (response: any) => {
            this.fetchAllEmployees();
          },
          (error: HttpErrorResponse) => {
            //
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  // tslint:disable-next-line:typedef
  fetchAllEmployees() {
    this.employeesService.getEmployees().pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // @ts-ignore
      this.dataSource.data = res;
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = false;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  applyFilter(filterValue: string) {
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
}
