<app-custom-dialog>
  <div header>Add New User</div>
  <div body>
    <form [formGroup]="submitForm" autocomplete="off">
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>FirstName</mat-label>
            <input formControlName="first_name" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('first_name').hasError('required')">
              Field is required (eg. Peter)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Last Name</mat-label>
            <input formControlName="last_name" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('last_name').hasError('required')">
              Field is required (eg. Doe)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('gender').hasError('required')">
              Field is required (eg. Male)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone Number</mat-label>
            <input formControlName="phone_no" mask="0000000000" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('phone_no').hasError('required')">
              Field is required
            </mat-error>
            <mat-error *ngIf="submitForm.get('phone_no').hasError('pattern')">
              Phone number should have 10 digits and start with 0 (eg. 0713000000)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder=""/>
            <mat-error *ngIf="submitForm.get('email').hasError('required')">
              Field is required (eg. peterjohn@example.com)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
              <mat-option value="ADMINISTRATOR">Admin</mat-option>
              <mat-option value="HUMAN_RESOURCE">Human Resource</mat-option>
            
            </mat-select>
            <mat-error *ngIf="submitForm.get('role').hasError('required')">
              Field is required (eg. ADMIN)
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Company</mat-label>
            <mat-select formControlName="com_id">
              <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitForm.get('com_id').hasError('required')">
              Field is required
            </mat-error>
          </mat-form-field>
        </div> -->
      </div>
    </form>
  </div>
  <div class="custom-action-buttons" footer>
    <button (click)="onSave()" [disabled]="submitForm.invalid || click" color="primary" mat-raised-button>SAVE</button>
    <button [mat-dialog-close]="true" color="warn" mat-raised-button>CANCEL</button>
  </div>
</app-custom-dialog>
