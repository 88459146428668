import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MandatoryContributionsRoutingModule } from './mandatory-contributions-routing.module';
import { AddMandatoryContributionComponent } from './add-mandatory-contribution/add-mandatory-contribution.component';
import { EditMandatoryContributionComponent } from './edit-mandatory-contribution/edit-mandatory-contribution.component';
import { ViewMandatoryContributionsComponent } from './view-mandatory-contributions/view-mandatory-contributions.component';
import {SharedModule} from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    AddMandatoryContributionComponent,
    EditMandatoryContributionComponent,
    ViewMandatoryContributionsComponent
  ],
  imports: [
    CommonModule,
    MandatoryContributionsRoutingModule,
    SharedModule
  ]
})
export class MandatoryContributionsModule { }
